import React from 'react';
import {useParams} from "react-router-dom";
import "./Game2.css";
import anime from 'animejs';
import Sketch from "react-p5";
import Matter, { Engine, World, Render, Bodies, Mouse, MouseConstraint, Constraint } from "matter-js";
import Bird from "./components/game2/bird";
import Object from "./components/game2/object";
import Ground from "./components/game2/ground";
import Floor from "./components/game2/floor";
import FloorC from "./components/game2/floorC";
import Container from "./components/game2/container";
import SlingShot from "./components/game2/slingshot";
import FlyingPoint from "./components/game2/flyingPoint";
import Star from "./components/game2/star";
import TopBar from "./components/game2/topbar";
import CountDown from "./components/CountDown";
import feedbackWindow from './components/feedbackWindow';
import * as dat from 'dat.gui';

import SettingsService from "../../services/settings";

export default function Game2(props) {  
  
  let { paramLevel } = useParams();  
  let ground5;
  let ground3;
  let ground2;
  let ground4;
  let paused = true;
  let ceil;
  let left;
  let right;
  const birds = [];
  let bird;
  let world, engine;
  let slingshot;
  let countDownTimer;
  let drag = false;
  let fW;
  let c1Img;
  let c2Img;
  let c3Img;
  let c4Img;
  let tileimages = [];
  let points = [];
  let containers = [];  
  let staron, staroff,point,nopoint;
  let prohibido = {x:0, y:0, w:70, h:70, image:"", visible:false};
  let star1, star2, star3, topBar;
  let birdPos = {x:300, y:180};
  let slingPos = {x:300, y:180};
  let timeOffset = 0;
  let gameover = false;
  let fired = false;
  let firedOut;
  let gameSize = {w:960,h:640};
  let stageSize = 700;
  let scale=1;
  let mouse, background, piedras, header, lanzador, lanzador_2,cup,mConstraint;
  let completed = false;
  const radio = 300;
  let objects = [];
  let isBird = false;
  let lastFlyingStar = undefined;
  const fx_rope = new Audio('/assets/fx_rope.mp3');
  
  let level = {
    x: 0,       
    y: 0,  
    difficult0:{time:[90000, 90000, 90000, 90000], points:[3,6,9,12]},
    difficult1:{time:[90000, 90000, 90000, 90000], points:[6,9,13,18]},
    difficult2:{time:[90000, 90000, 90000, 90000], points:[6,9,13,18]}     
  };
  let homeButton;

  //const gui = new dat.GUI();
  
  let panel = {
    MARCH_SPEED : 10,
    DISTANCE_CORRECTOR : 1.9,
    LENGTH_CORRECTOR : 12
  };

  let isMusicEnabled = SettingsService.isMusicEnabled();
  let isFxEnabled = SettingsService.isFxEnabled();
  let difficulty = SettingsService.getDifficulty();
  

  const preload = (p5) => {    
    tileimages.push(p5.loadImage("/assets/game2/bulb1.png"));
    tileimages.push(p5.loadImage("/assets/game2/bulb2.png"));
    tileimages.push(p5.loadImage("/assets/game2/bottle.png"));
    tileimages.push(p5.loadImage("/assets/game2/banana.png"));    
    c1Img = p5.loadImage("/assets/game2/bombillas.png");
    c2Img = p5.loadImage("/assets/game2/tubos.png");
    c3Img = p5.loadImage("/assets/game2/vidrio.png");
    c4Img = p5.loadImage("/assets/game2/organico.png");
    point = p5.loadImage('/assets/game1/star.png');
    //nopoint = p5.loadImage('/assets/nopoint.svg');        
    prohibido.image = p5.loadImage("/assets/game2/prohibicion.png");
    staron = p5.loadImage("/assets/game1/star.png");
    staroff = p5.loadImage("/assets/game3/staroff.png");
    piedras = p5.loadImage("/assets/game2/piedras.png");
    header = p5.loadImage("/assets/game2/header.png");
    lanzador = p5.loadImage("/assets/game2/lanzador.png");
    lanzador_2 = p5.loadImage("/assets/game2/lanzador_2.png");
    cup = p5.loadImage("/assets/game2/cup.png");

  };

  
  const setup = (p5, canvasParentRef) => {
    
    p5.createCanvas(gameSize.w, gameSize.h).parent(canvasParentRef);    
    scaleCanvas(p5);
    
    level.x = p5.width/2 - stageSize/2;
    level.y = 140;
    engine = Engine.create();
    world = engine.world;
    //engine.gravity.y = 0.98;  

    drag = false;
    gameover = false;
    completed = false;
    fW = new feedbackWindow(p5, "Game2", p5.width/2, p5.height/2, scale, changeLevelCallback);
    fW.setLevel(paramLevel);
    
    /*PAREDES*/
    left = new Ground(level.x-130, p5.height/2, 20, p5.height, world);
    right = new Ground(level.x+830, p5.height/2, 20, p5.height, world);    
    //ceil = new Ground(p5.width/2, 0, p5.width, 20, world);    
    
    ground2 = new FloorC(level.x-30, 290, 10, 300, world);    
    ground3 = new FloorC(level.x-90, p5.height-50, 480, 300, world);    
    ground4 = new FloorC(level.x+675 , p5.height-40, 480, 300, world);
    ground5 = new FloorC(level.x+590 , p5.height+20, 480, 300, world);    
    /*PAREDES*/

    /*CONTAINER*/
    containers.push(new Container(1, level.x + 480, 420, 50, 90, c1Img, world));
    containers.push(new Container(2, level.x + 540, 390, 68, 151, c2Img, world));
    containers.push(new Container(3, level.x + 650, 370, 125, 93, c3Img, world));
    containers.push(new Container(4, level.x + 750, 345, 84, 92, c4Img, world));
    /*CONTAINER*/

    /*OBJECTS*/
    rellenaObjetos();
    /*OBJECTS*/

    /*TOPBAR*/
    countDownTimer = new CountDown(p5, level["difficult" + difficulty].time[paramLevel-1]);
    
    star1 = new Star(staroff, staron, level.x, 10);
    star2 = new Star(staroff, staron, level.x, 10);
    star3 = new Star(staroff, staron, level.x, 10);
    /*TOPBAR*/

    bird = new Bird(200, 370, 25, tileimages[0], 1, world); 
    slingPos.x = level.x + 68;
    slingPos.y = 340;
    slingshot = new SlingShot(slingPos.x, slingPos.y, bird.body, 30, world);
    topBar = new TopBar(0, level.x, 0, 430, 0, header, level["difficult" + difficulty].points[paramLevel-1]);
    
    countDownTimer.setPosition({x:level.x + 650, y:50})    
    star1.setX(level.x + 150);
    star2.setX(level.x + 320);
    star3.setX(level.x + 480);
  
    mouse = Mouse.create(canvasParentRef);
    let mouseScale = 1 + (1 / (scale / (1 - scale)))        
    Mouse.setScale(mouse, {x: mouseScale, y: mouseScale});
    const options = {
      mouse: mouse
    };

    // A fix for HiDPI displays
    //mouse.pixelRatio = p5.pixelDensity();
    mConstraint = MouseConstraint.create(engine, options);
    World.add(world, mConstraint);
    
    document.getElementById("root").style.backgroundImage = "url(/assets/game2/bg.jpg)";
    document.getElementById("overlay_left").style.display = "none";
    document.getElementById("overlay_right").style.display = "none";
    
    Matter.Events.on(engine, 'collisionStart', function(event) {
      let pairs = event.pairs;
      if (drag) return;
      
      pairs.forEach(({ bodyA, bodyB }) => {
        if ((bodyA.label === "bird" && bodyB.label.indexOf("container_")>=0)) {
          fired = false;
          clearTimeout(firedOut)
          World.remove(engine.world, bodyA); 
          isBird = false;
          if (bodyA.container == parseInt(bodyB.label.replace("container_","")))
            incrementScore(p5, bodyB);
          else decrementScore(p5, bodyB);
        }else if ((bodyB.label === "bird" && bodyA.label.indexOf("container_")>=0)) {
          fired = false;
          clearTimeout(firedOut)
          World.remove(engine.world, bodyB);
          isBird = false;
          if (bodyB.container == parseInt(bodyA.label.replace("container_","")))
            incrementScore(p5, bodyA);
          else decrementScore(p5, bodyA); 
        }
      });
      
    });

    homeButton = p5.createButton("");
    homeButton.position(level.x-60,48);
    homeButton.id("homeButton");
    homeButton.parent("Game2");
    homeButton.style("background-image","url(/assets/game1/btn_home.png)");
    homeButton.mousePressed(button1HandleClick);

    if (document.getElementById("p5_loading_intro")) {
      document.getElementById("p5_loading_intro").style.display = "block";
      document.getElementById("p5_loading_intro").style.opacity = 1;
      anime({
        targets: document.getElementById("p5_loading_intro"),
        opacity:0,
        duration: 1000,
        delay:1000,
        easing: 'easeInOutQuad',   
        complete: (anim) => {
          document.getElementById("p5_loading_intro").style.display = "none";
        }
      });
    }

    if (!SettingsService.isTutorialAlreadyPlayed(2)) {      
      document.getElementById("game2Ayuda").style.display = "block";
      SettingsService.changeTutorialPlayed(2, true);
    } else {
      document.getElementById("game2Ayuda").style.display = "none";
      startGame();
    }      

    /*
    gui.add(panel, 'MARCH_SPEED', 0 , 10);
    gui.add(panel, 'DISTANCE_CORRECTOR', 0 , 10);
    gui.add(panel, 'LENGTH_CORRECTOR', 0, 200);
    */

  }

  const changeLevelCallback = (level) => {
    paramLevel = level;
  }

  const button1HandleClick = () => {
    document.getElementById("root").style.backgroundImage = "none";
    window.location.href = "/#/menu";    
  }

  const incrementScore = (p5, body) =>{
    if (gameover) return;

    containers[parseInt(body.label.replace("container_",""))-1].jiggle();

    let score = topBar.getScore();
    score++;
    topBar.setScore(score);

    const fx_1 = new Audio('/assets/fx_correct.mp3');
    if (isFxEnabled) {
      fx_1.play();
    }

    let star = undefined; 

    if (score >= level["difficult" + difficulty].points[paramLevel-1] * 1){
      if (lastFlyingStar != 'star3') {
        lastFlyingStar = 'star3';
        star = star3;
      }
    } else if (score >= level["difficult" + difficulty].points[paramLevel-1] * 0.60){
      if (lastFlyingStar != 'star2') {
        lastFlyingStar = 'star2';
        star = star2;
      }
    } else if (score >= level["difficult" + difficulty].points[paramLevel-1] * 0.30){
      if (lastFlyingStar != 'star1') {
        lastFlyingStar = 'star1';
        star = star1;
      }
    }
    
    if (star) points.push(new FlyingPoint("point"+points.length, body.position.x, body.position.y, point, star.x, star.y, 50, 50, star, false, topBar, score));
    newBird(p5);

  }

  const rellenaObjetos = () =>{
    for (let i = 0; i < 10; i++){
      let random = getRandomTile();
      objects.push(new Object(level.x-50, 340, 25, random, tileimages[random], world));
    }
  }

  const getRandomTile = () => {
    return Math.floor(Math.random() * tileimages.length);
  }

  const showError = (container) =>{
    prohibido.visible = true;
    setTimeout(() =>{
      prohibido.visible = false;
    },800)
    prohibido.x =  container.body.position.x - prohibido.w/2;
    prohibido.y =  container.body.position.y - prohibido.h/2;
    const fx_1 = new Audio('/assets/fx_nopoint.mp3');
    if (isFxEnabled) {
      fx_1.play();
    }
  }

  const decrementScore = (p5, body) =>{
    if (gameover) return;
    containers[parseInt(body.label.replace("container_",""))-1].jiggle();
    showError(containers[parseInt(body.label.replace("container_",""))-1]);   
    newBird(p5);
  }

  const drawTrajectoryPath = (p5, distance, startPosition, angle) => {
    if (difficulty < 2) {
      const BULLET_SPEED = distance * 2;
      const LENGTH_CORRECTOR = slingshot.sling.length/2;
      const correctionFactor = 0.99;
      const theta = Math.PI - angle;
      const radius = 2.5;
      
      console.log(slingshot.sling)
      
      p5.push();
      p5.noStroke();
      p5.fill('#88B100');
      
      for (let t = 0; t < 10; t += 0.1) {
        const x = BULLET_SPEED * t * Math.cos(theta) * correctionFactor;
        const y = BULLET_SPEED * t * Math.sin(theta) * correctionFactor - (LENGTH_CORRECTOR * t * t);
        const circleX = x + startPosition.x;
        const circleY = startPosition.y - y;
        
        if (y < -15) break;
        
        p5.circle(circleX, circleY, radius);
      }
      
      p5.pop();
    }
  }

  

  const newBird = (p5) => {    
    if (isBird) return;
    let remove = objects.pop();
    World.remove(engine.world, remove.body); 
    fired = false;
    isBird = true;
    bird = new Bird(200, 370, 25, tileimages[remove.imageType], parseInt(remove.imageType)+1, engine.world);     
    if (objects.length<2) rellenaObjetos(); 
    slingshot.attach(bird.body);
  }

  const draw = (p5) => { 
    p5.clear();    
    Engine.update(engine);
    
    topBar.show(p5);
    
    let score = topBar.getScore();

    if (score >= (level["difficult" + difficulty].points[paramLevel-1] * 1))
      star3.render(p5, 1)
    else
      star3.render(p5, 0)

    if (score >= (level["difficult" + difficulty].points[paramLevel-1] * 0.6))
      star2.render(p5, 1)
    else 
      star2.render(p5, 0)

    if (score >= (level["difficult" + difficulty].points[paramLevel-1] * 0.3))
      star1.render(p5, 1)
    else 
      star1.render(p5, 0)

    countDownTimer.render(p5);

    p5.image(lanzador_2,level.x+60,310);

    

    timeOffset = 0;

    let x1=slingPos.x;
    let y1=slingPos.y;
    let x2=bird.body.position.x;
    let y2=bird.body.position.y;

    const _distance = p5.dist(x1, y1, x2, y2);
    const _angle = p5.atan2(y2 - y1, x2 - x1);
    if (slingshot.sling.bodyB!==null && _distance>50){
      drawTrajectoryPath(p5, _distance,  {x:bird.body.position.x, y:bird.body.position.y}, _angle);    
      if (isFxEnabled) fx_rope.play();
    }
      
    
    
    /*if (slingshot.sling.bodyB!==null && _distance>20){
      p5.push();
        p5.noStroke()
        //p5.line(x1, y1, x2, y2);
        //p5.ellipse(x1, y1, 7, 7);
        //p5.ellipse(x2, y2, 7, 7);
        p5.translate((x1 + x2) / 2, (y1 + y2) / 2);
        p5.rotate(p5.atan2(y2 - y1, x2 - x1));
        p5.text(Math.round(p5.nfc(_distance, 1)), 0, 15);
      p5.pop();
    }*/
    
    p5.image(lanzador,level.x+60,310);

    
    bird.show(p5);
    slingshot.show(p5);

    if (slingshot.sling.bodyB!==null){      
      p5.push();
        p5.noStroke();
        p5.fill("#2b160a");
        p5.imageMode(p5.CENTER);      
        p5.circle(bird.body.position.x, bird.body.position.y, 5); 
        p5.translate(bird.body.position.x, bird.body.position.y);      
        p5.rotate(p5.atan2(y2 - y1, x2 - x1)-p5.PI*180/270);
        p5.image(cup,10,-8);      
      p5.pop();
    }

    p5.image(lanzador_2,level.x+60,310);

    if (fired){      
      fx_rope.pause();
      if (bird.body.position.y>p5.height-200) newBird(p5)
      //else if (bird.body.position.y<0) newBird(p5)
      else if (bird.body.position.x<p5.width/2) newBird(p5)
    }    

    containers.map((el)=>{
      el.show(p5);  
    })

    objects.map((el)=>{
      el.show(p5);  
    })

    if (prohibido.visible) p5.image(prohibido.image,prohibido.x,prohibido.y,prohibido.w,prohibido.h );

    let i = points.length
    while (i--) {
        points[i].render(p5);
        if (points[i].isDie()) {           
            points.splice(i, 1);
        } 
    }

    if (score == level["difficult" + difficulty].points[paramLevel-1] || countDownTimer.isEnded()) gameover = true;

    if (gameover && !completed) {
      completed = true;
      let stars = 0;
      if (score >= level["difficult" + difficulty].points[paramLevel-1] * 0.3) stars=1;
      if (score >= level["difficult" + difficulty].points[paramLevel-1] * 0.6) stars=2;
      if (score >= level["difficult" + difficulty].points[paramLevel-1] * 1) stars=3;      
      fW.setStars(stars);

      setTimeout(() => {
        fW.setVisible();
      },500);
      
    }

    if (gameover) fW.render(p5);  

  };

  const mouseReleased = (p5, event) =>{

    if (paused) return;
    if (fx_rope) fx_rope.pause();
    if (slingshot.sling.bodyB!==null && p5.dist(slingPos.x, slingPos.y, bird.body.position.x, bird.body.position.y)>50){      
      setTimeout(() => {
        firedOut = setTimeout(() => {
          fired = true;                
        }, 2500);
        const fx_1 = new Audio('/assets/fx_lanzo.mp3');
        if (isFxEnabled) {
          fx_1.play();
        }
        slingshot.fly();            
        drag = false; 
        isBird = false;   
      }, 50);
    }  
  
  }

  const scaleCanvas = (p5) => {
    scale = (window.innerHeight+15) / p5.height;
    document.getElementById("Game2").style.transform = "translate(-50%, -50%) scale("+scale+")";            
  }

  const startGame = ()=>{
    paused = false;
    countDownTimer.start();
  }

  const closeHelp = () => {
    document.getElementById("game2Ayuda").style.display = "none";
    startGame();
  }

  return (
    <div className='Game2' id="Game2">
        <img id='game2Ayuda' onClick={closeHelp} src="ayudas/game2.png"/>
        <Sketch mouseReleased={mouseReleased} setup={setup} preload={preload} draw={draw} />
    </div>
  )
  
}