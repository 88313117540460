import React from 'react';
import {
  HashRouter as Router,
  Routes,
  Route  
} from "react-router-dom";
import Menu from "./components/routes/Menu.js";
import Datos from "./components/routes/Datos.js";
import Dificultad from "./components/routes/Dificultad.js";
import Game1 from "./components/routes/Game1.js";
import Game2 from "./components/routes/Game2.js";
import Game3 from "./components/routes/Game3.js";
import Game4 from "./components/routes/Game4.js";
import SettingsService from "./services/settings";

class App extends React.Component {
    
    constructor(props) {
        super(props);
        document.addEventListener("pause", this.manageAppPause);
        document.addEventListener("resume", this.manageAppResume);
    }

    componentDidMount() {
        let isMusicEnabled = SettingsService.isMusicEnabled();
        console.log('isMusicEnabled', isMusicEnabled);
        if (isMusicEnabled) {
          let music = document.getElementById("music");
          if (music) {
            music.src = '/assets/music_loop1.mp3';
            music.volume = 0.5;
            music.play();
          }
        }
    }

    componentWillUnmount(){
        document.removeEventListener("pause", this.manageAppPause);
        document.removeEventListener("resume", this.manageAppResume);
    }
    
    manageAppPause() {
        document.getElementById("music").pause();
    }
    
    manageAppResume() {    
        document.getElementById("music").play();
    }

    render(){
        return (    
            <>
            <div id="overlay_left"></div>
            <div id="overlay_right"></div>
            <div id="p5_loading"><img src="/assets/general/logo.png"></img></div>
            <div id="p5_loading_intro"><img src="/assets/general/logo.png"></img></div>
            <audio loop id="music"></audio>
            <Router>
                <Routes>
                    <Route exact path="/" element={<Menu/>}/>
                    <Route exact path="/menu" element={<Menu/>}/>
                    <Route exact path="/datos" element={<Datos/>}/>
                    <Route exact path="/dificultad" element={<Dificultad/>}/>
                    <Route exact path="/game1/:paramLevel" element={<Game1/>}/>
                    <Route exact path="/game2/:paramLevel" element={<Game2/>}/>
                    <Route exact path="/game3/:paramLevel" element={<Game3/>}/>
                    <Route exact path="/game4/:paramLevel" element={<Game4/>}/>                    
                </Routes>
            </Router>                
            </>
            
        );
    }
}

export default App;