import anime from 'animejs';

import SettingsService from "../../../../services/settings";

export default class Bubble {
    constructor(id, initialscore, staroff, staron) {
        this.id = id;
        this.staroff = staroff;
        this.staron = staron;
        this.x = 0;
        this.y = 15;
        this.w = 50;
        this.h = 50;
        this.scale = 1;
        this.score = initialscore;
        this.anim = false;
        this.isFxEnabled = SettingsService.isFxEnabled();
    }

    setX(newx){
        this.x = newx;
    }

    getX(){
        return this.x;
    }

    getY(){
        return this.y;
    }

    jiggle(){
        if (this.anim) return;
        this.score=1;
        this.anim = true;
        anime({
            targets: this,
            scale: 2,
            duration: 300,
            easing: 'easeInOutQuad',
            direction: 'alternate',
            complete: (anim) => {
                if (anim.completed) this.anim = false;
            }   
        });
    }
    
    setImage(newimage){
        this.image = newimage;
    }

    playSound(){
        const fx_1 = new Audio('/assets/fx_1.mp3');
        if (this.isFxEnabled) {
            fx_1.play();
        }    
    }

    setScore(newScore) {
        this.score = newScore;
        if (this.score==1) { 
            this.playSound();
            this.jiggle();
        }
    }

    getScore() {
        return this.score;
    }    

    displayImage(p5){
        p5.push();
        p5.translate(this.x+(this.w/2), this.y+(this.h/2));
        p5.scale(this.scale,this.scale);
        if (this.score===0)
            p5.image(this.staroff, -(this.w/2), -(this.h/2), this.w, this.h);              
        else p5.image(this.staron, -(this.w/2), -(this.h/2), this.w, this.h);
        p5.pop();
    }
  
    render(p5) {                 
        this.displayImage(p5);                
    }
  }