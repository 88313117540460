import Matter from "matter-js";

export default class Object {
    constructor(x, y, r, imageType, image, _world) {
      const options = {
        label:"object",
        restitution: 0
      };
      this.imageType = imageType;
      this.body = Matter.Bodies.rectangle(x, y, image.width/5.5, image.height/5.5, options);
      Matter.Body.setMass(this.body, 5);
      Matter.World.add(_world, this.body);
      this.image = image;
    }

    getContainer() { return this.container; }
  
    show(p5) {
      const pos = this.body.position;
      const angle = this.body.angle;
      p5.push();
      p5.translate(pos.x, pos.y);
      p5.rotate(angle);
      p5.imageMode(p5.CENTER);
      p5.image(this.image, 0, 0, this.image.width/5.5, this.image.height/5.5);
      p5.pop();
    }
  }