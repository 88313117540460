import anime from 'animejs';

import SettingsService from "../../../../services/settings";

export default class Star {
    constructor(image, staron, x, y) {
        this.image = image;
        this.staron = staron;
        this.x = x;
        this.y = y;
        this.w = 60;
        this.h = 60;
        this.scale = 1;
        this.anim = false;
        this.isFxEnabled = SettingsService.isFxEnabled();
    }

    setX(newx){
        this.x = newx;
    }

    jiggle(){
        if (this.anim) return;
        this.anim = true;
        anime({
            targets: this,
            scale: 2,
            duration: 300,
            easing: 'easeInOutQuad',
            direction: 'alternate',
            complete: (anim) => {
                if (anim.completed) this.anim = false;
            }   
        });
    }
    
    setImage(newimage){
        this.image = newimage;
    }

    playSound(){
        const fx_1 = new Audio('/assets/fx_star.mp3');
        if (this.isFxEnabled) {
            fx_1.play();
        }
    }

    playSound2(){
        const fx_2 = new Audio('/assets/fx_2.mp3');
        if (this.isFxEnabled) {
            fx_2.play();
        }
    }

    setScore(error) {
        if (error) this.playSound2();
        else {
            this.playSound();
            this.jiggle();
        }
        
    }

    displayImage(p5, state){
        p5.push();
        p5.translate(this.x+(this.w/2), this.y+(this.h/2));
        p5.scale(this.scale,this.scale);
        if (state==0){
            if (this.scale>1)
                p5.image(this.staron, -(this.w/2), -(this.h/2), this.w, this.h);
            else p5.image(this.image, -(this.w/2), -(this.h/2), this.w, this.h);              
        }
        else p5.image(this.staron, -(this.w/2), -(this.h/2), this.w, this.h);
        p5.pop();
    }
  
    render(p5, state) {        
        this.displayImage(p5, state);                
    }
  }