
import Matter from "matter-js";

export default class Ground {
    constructor(x, y, w, h, _world) {
      const options = {
        isStatic:true,
        label: 'ground',
        restitution: 0.5
      };
      this.x = x;
      this.y = y;
      this.w = w;
      this.h = h;
      this.body = Matter.Bodies.rectangle(x, y, w, h, options);
      Matter.World.add(_world, this.body);
      
    }
  
    show(p5) {
      const pos = this.body.position;
      const angle = this.body.angle;
      p5.push();
      p5.translate(pos.x, pos.y);
      p5.rotate(angle);
      p5.noStroke();
      p5.fill(255);
      p5.rectMode(p5.CENTER);
      p5.rect(0, 0, this.w, this.h);
      p5.pop();
    }
  }