import anime from 'animejs';

import SettingsService from "../../../../services/settings";

export default class TileCircular {
    constructor(id, tileType, image, x, y, w, h, star, fixedimage, img_tile_mask) {
        this.id = id;
        this.tileType = tileType;
        this.fixedimage = fixedimage;
        this.image = image;
        this.star = star;        
        this.x = x;
        this.y = y;    
        this.w = w;
        this.img_tile_mask = img_tile_mask;
        this.h = h;    
        this.newH = h;
        this.bubble = undefined;
        this.moving = false;
        this.selected = false;     
        this.inPlace = false;   
        this.die = false;    
        this.ratio = 1;
        if (this.tileType==2 || this.tileType==3) this.withStar = true;
        else this.withStar = false;
        this.starX = this.x+(this.w/2)-(this.w/6);
        this.starY = this.y+(this.h/2)-(this.h/6);
        this.isFxEnabled = SettingsService.isFxEnabled();
    }

    getX() { return this.x; }
    getY() { return this.y; }
    getTileType() { return this.tileType; }

    setTileType(newType, image) { 
        this.tileType=newType;
        this.image=image;
    }

    playSound(){
        const fx_1 = new Audio('/assets/fx_correct.mp3');
        if (this.isFxEnabled) {
            fx_1.play();
        }
    }

    setInPlace(value) {
        this.inPlace = value;
    }

    setResize(x, y, w, h) { 
        this.x = x;
        this.y = y;    
        this.w = w;
        this.h = h;   
        this.starX = this.x+(this.w/2)-(this.w/6);
        this.starY = this.y+(this.h/2)-(this.h/6);
    }

    select() {
        this.selected = true;
    }

    deselect() {
        this.selected = false;
    }

    isMoving() { return this.moving; }

    hasStar(){
        return this.withStar;
    }

    activeStars(bubble, xfin, yfin){  
        if (this.moving) return false;  
        if (!this.inPlace) return false;
        this.bubble = bubble;
        this.moving = true;    
        anime({
            targets: this,
            starX: xfin,
            starY: yfin,
            duration: 500,
            easing: 'easeInOutQuad',
            delay: Math.random()*500,
            complete: (anim) => {
                if (anim.completed) {
                    this.die = true;
                    this.moving = false;
                    if (this.bubble) this.bubble.setScore(1);
                }
            }            
        });
        return true;
    }

    move(x,y){
        this.moving = true;
        anime({
            targets: this,
            x: x,
            y: y,
            starX : x+(this.w/2)-(this.w/6),
            starY : y+(this.h/2)-(this.h/6),
            duration: 60,
            easing: 'linear',
            complete: (anim) => {
                if (anim.completed) {
                    this.moving = false;
                    this.selected = false;
                }
            }            
        });
    }

    displayMovingImage(p5){        
        if (!this.image) return;
        let pg = p5.createGraphics(this.w, this.h);        
        pg.imageMode(p5.CENTER);
        pg.rotate(p5.PI / 180 * p5.frameCount);
        pg.image(this.image, 0, 0, this.w*2, this.h*2);  

        if (this.tileType==8){
            p5.image(pg, this.x, this.y, this.w, this.h);
        }
        else if (this.tileType==6){
            p5.push();
                p5.translate(this.x, this.y);
                p5.rotate(p5.PI)    
                p5.image(pg, -this.w, -this.h, this.w, this.h);
            p5.pop();
        } 
        else if (this.tileType==5){
            p5.push();
                p5.translate(this.x, this.y);
                p5.rotate(p5.PI / 180 * 270)    
                p5.image(pg,-this.w, 0, this.w, this.h);
            p5.pop();
        } 
        else if (this.tileType==7){
            p5.push();
                p5.translate(this.x, this.y);
                p5.rotate(p5.PI/180 * 90)    
                p5.image(pg, 0, -this.h, this.w, this.h);
            p5.pop();
        } 

        
        pg.remove();
    }

    displayImage(p5){        
        if (!this.image) return;
        let pg = p5.createGraphics(this.w, this.h);        
        pg.imageMode(p5.CENTER);
        pg.rotate(p5.PI / 180);
        pg.image(this.image, 0, 0, this.w*2, this.h*2);  

        if (this.tileType==8){
            p5.image(pg, this.x, this.y, this.w, this.h);
        }
        else if (this.tileType==6){
            p5.push();
                p5.translate(this.x, this.y);
                p5.rotate(p5.PI)    
                p5.image(pg, -this.w, -this.h, this.w, this.h);
            p5.pop();
        } 
        else if (this.tileType==5){
            p5.push();
                p5.translate(this.x, this.y);
                p5.rotate(p5.PI / 180 * 270)    
                p5.image(pg,-this.w, 0, this.w, this.h);
            p5.pop();
        } 
        else if (this.tileType==7){
            p5.push();
                p5.translate(this.x, this.y);
                p5.rotate(p5.PI/180 * 90)    
                p5.image(pg, 0, -this.h, this.w, this.h);
            p5.pop();
        } 
        pg.remove();
        
    }

    displaySelected(p5){
        if (!this.selected) return;
        p5.noStroke();        
        p5.fill(p5.color(184, 209, 129, 102));     
        p5.rect(this.x, this.y, this.w, this.h);
    }

    displayStar(p5){        
        p5.push();
        p5.translate(this.starX, this.starY);
        p5.image(this.star, 0, 0, this.w/3, this.h/3); 
        p5.pop();
    }

    displayFixed(p5){   
        if (!this.fixedimage) return;     
        p5.image(this.fixedimage, this.x, this.y, this.w, this.h); 
    }
  
    render(p5) {     
        if (this.tileType>=0){    
            if (!this.inPlace) this.displayImage(p5);     
            else this.displayMovingImage(p5);            
            this.displayFixed(p5);
            this.displaySelected(p5);                            
            if (this.withStar && !this.die) this.displayStar(p5);    
        }
    }
  
}