import anime from 'animejs';

export default class flyingPoint {
    constructor(id, xini, yini, image, xfin, yfin, w, h, star, error, topbar, score) {
        this.id = id;
        this.image = image;
        this.x = xini;
        this.y = yini;
        this.xfin = xfin;
        this.yfin = yfin;
        this.w = w;
        this.h = h;
        this.rotate = 0;
        this.topbar = topbar;
        this.score = score;
        this.star = star;
        this.die = false;
        this.error = error || false;
        this.jiggle();
    }

    isDie() {
        return this.die;
    }

    jiggle(){
        anime({
            targets: this,
            x: this.xfin,
            y: this.yfin,
            rotate:3060,
            duration: 1000,
            easing: 'easeInOutQuad',
            complete: (anim) => {
                if (anim.completed) {
                    this.die = true;
                    this.star.setScore(this.error);
                    this.topbar.setScore(this.score);
                }
            }            
        });
    }

    displayImage(p5){
        p5.push();
        p5.translate(this.x+(this.w/2), this.y+(this.h/2));
        p5.rotate(p5.PI/180*this.rotate);
        p5.image(this.image, -(this.w/2), -(this.h/2), this.w, this.h);              
        p5.pop();
    }
  
    render(p5) {         
        this.displayImage(p5);                
    }
  
}