import React, { useEffect, useState, useCallback, useRef} from 'react';
import {useParams} from "react-router-dom";
import _ from 'lodash';
import "./Game1.css";
import anime from 'animejs';
import Sketch from "react-p5";
import Bubble from "./components/game1/bubble";
import FlyingBulb from "./components/game1/flyingBulb";
import CountDown from "./components/CountDown";
import feedbackWindow from './components/feedbackWindow';
import Hammer from "hammerjs";
import SettingsService from "../../services/settings";

export default function Game1() {  
  
  let { paramLevel } = useParams();
  const [ayuda, setAyuda] = useState(true);

  let level = {
    x: 0,       
    y: 0,       
    columns: 10,   
    rows: 5,      
    tilewidth: 80,
    tileheight: 80,
    tiles: [],    
    selectedtile: { selected: false, column: 0, row: 0 },
    difficult0:{time:[300000, 200000, 100000, 100000], points:[8,13,18,23]},
    difficult1:{time:[300000, 200000, 100000, 100000], points:[10,15,20,25]},
    difficult2:{time:[300000, 200000, 100000, 100000], points:[12,17,22,27]}
  };

  let currentmove = { column1: 0, row1: 0, column2: 0, row2: 0 };
  let gamestates = { init: 0, ready: 1, resolve: 2 };
  let gamestate = gamestates.init;
  let animationstate = 0;
  let animationtime = 0;
  let animationtimetotal = 0.3;
  let showmoves = false;
  let drag = false;
  let paused = true;
  let lastframe = 0;
  let aibot = false;
  let clusters = [];  // { column, row, length, horizontal }
  let moves = [];     // { column1, row1, column2, row2 }
  let gameover = false;
  let tileimages = [];
  let tileimagesselect = [];  
  const numImages = 6;
  const scoreTypes = [{id:0},{id:1},{id:2}];
  let targetPoints = 10;
  let flyingBulbs = [];
  let randoms=[];
  let countDownTimer;
  let staron, staroff,img_tablero,img_header,img_planta_1,img_planta_2,background;
  let c = [];
  let gameSize = {w:960,h:640};
  let scale=1;
  let fW;
  let homeButton;
  let playSound = false;
  let completed = false;
  let isMusicEnabled = SettingsService.isMusicEnabled();
  let isFxEnabled = SettingsService.isFxEnabled();
  let difficulty = SettingsService.getDifficulty();
  let levelwidth = 0;
  let levelheight = 0;

  const preload = (p5) => {           
    
    for (let i=0; i<numImages; i++){
      tileimages.push(p5.loadImage("/assets/game1/b"+(i+1)+".png"));
    }

    for (let i=0; i<numImages; i++){
      tileimagesselect.push(p5.loadImage("/assets/game1/b"+(i+1)+"s.png"));
    }    

    c[0] = p5.loadImage("/assets/game1/c1.png");
    c[1] = p5.loadImage("/assets/game1/c2.png");
    c[2] = p5.loadImage("/assets/game1/c3.png");
    staron = p5.loadImage("/assets/game1/star.png");
    img_tablero = p5.loadImage("/assets/game1/tablero.png");
    img_header = p5.loadImage("/assets/game1/header.png");
    img_planta_1 = p5.loadImage("/assets/game1/planta_izq.png");
    img_planta_2 = p5.loadImage("/assets/game1/planta_der.png");
    background = p5.loadImage("/assets/game1/bg.jpg");

  };

  const fillRandoms = () => {
    let _randoms = [];
    let counter = 0;
    /*ASEGURAMOS QUE HAYA BOMBILLAS DEL TIPO CORRECTO */
    scoreTypes.map((el, i)=>{      
      for(let r=0; r<targetPoints*2; r++) {
        _randoms.push(el.id);
        counter++;
      }      
    });

    for(let r=0; r<((level.columns*level.rows)-counter); r++) {
      _randoms.push(getRandomTile());
    }
    
    randoms = _.shuffle(_randoms);
  }

  const scaleCanvas = (p5) => {
    scale = (window.innerHeight+15) / p5.height;
    document.getElementById("Game1").style.transform = "translate(-50%, -50%) scale("+scale+")";        
  }

  const setup = (p5, canvasParentRef) => {    
    p5.createCanvas(gameSize.w, gameSize.h).parent(canvasParentRef);        
    scaleCanvas(p5);    
    level.x = gameSize.w/2 - (level.tilewidth*level.columns)/2;
    level.y = 140;

    levelwidth = level.columns * level.tilewidth;
    levelheight = level.rows * level.tileheight;

    targetPoints = level["difficult" + difficulty].points[paramLevel-1];

    fW = new feedbackWindow(p5, "Game1", p5.width/2, p5.height/2, scale, changeLevelCallback);
    fW.setLevel(paramLevel);
    
    for (let i=0; i<level.columns; i++) {
      level.tiles[i] = [];
      for (let j=0; j<level.rows; j++) {
          level.tiles[i][j] = { type: 0, shift:0 }
      }
    }  
    
    homeButton = p5.createButton("");
    homeButton.position(level.x,48);
    homeButton.id("homeButton");
    homeButton.parent("Game1");
    homeButton.style("background-image","url(/assets/game1/btn_home.png)");
    homeButton.mousePressed(button1HandleClick);
    completed = false;
    let options = {
      preventDefault: true
    };

    let hammer = new Hammer(document.body, options);
    hammer.get('swipe').set({
      direction: Hammer.DIRECTION_ALL
    });

    hammer.on("swipe", swiped);
    document.getElementById("root").style.backgroundImage = "url(/assets/game1/bg.jpg)";
    document.getElementById("overlay_left").style.display = "none";
    document.getElementById("overlay_right").style.display = "none";    
    
    newGame(p5);    
  }

  const changeLevelCallback = (level) => {
    paramLevel = level;
  }

  const swap = (x1, y1, x2, y2) => {
    if (!level.tiles[x2][y2]) return;
    if (!level.tiles[x1][y1]) return;
    if (level.tiles[x2][y2].type === undefined) return;
    if (level.tiles[x1][y1].type === undefined) return;
    let typeswap = level.tiles[x1][y1].type;    
    level.tiles[x1][y1].type = level.tiles[x2][y2].type;
    level.tiles[x2][y2].type = typeswap;
  }

  const mouseSwap = (c1, r1, c2, r2) => {
    currentmove = {column1: c1, row1: r1, column2: c2, row2: r2};
    level.selectedtile.selected = false;
    animationstate = 2;
    animationtime = 0;
    gamestate = gamestates.resolve;
  }

  const findMoves = () => {
    moves = []
    for (let j=0; j<level.rows; j++) {
        for (let i=0; i<level.columns-1; i++) {
            swap(i, j, i+1, j);
            findClusters();
            swap(i, j, i+1, j);
            if (clusters.length > 0) {
                moves.push({column1: i, row1: j, column2: i+1, row2: j});
            }
        }
    }

    for (let i=0; i<level.columns; i++) {
        for (let j=0; j<level.rows-1; j++) {
            swap(i, j, i, j+1);
            findClusters();
            swap(i, j, i, j+1);
            if (clusters.length > 0) {
                moves.push({column1: i, row1: j, column2: i, row2: j+1});
            }
        }
    }

    clusters = []
  }

  const findClusters = () => {
    clusters = []

    let isScoreable = false;

    for (let j=0; j<level.rows; j++) {
        let matchlength = 1;
        for (let i=0; i<level.columns; i++) {
            let checkcluster = false;
            isScoreable = false;
            if (i == level.columns-1) {
                checkcluster = true;
            } else {
                if (level.tiles[i][j].type == level.tiles[i+1][j].type &&
                    level.tiles[i][j].type != -1) {
                    matchlength += 1;
                } else {
                    checkcluster = true;
                }                
            }
  
            

            if (checkcluster) {
                if (matchlength >= 3) {
                    if (_.findIndex(scoreTypes, {id:level.tiles[i][j].type})>=0){
                      isScoreable = true;
                    }
                    clusters.push({ column: i+1-matchlength, row:j,
                                    length: matchlength, horizontal: true, isScoreable:isScoreable, scoreType: level.tiles[i][j].type  });
                }

                matchlength = 1;
            }
        }
    }

    for (let i=0; i<level.columns; i++) {
        let matchlength = 1;
        for (let j=0; j<level.rows; j++) {
            let checkcluster = false;
            isScoreable = false;
            if (j == level.rows-1) {
                checkcluster = true;
            } else {
                if (level.tiles[i][j].type == level.tiles[i][j+1].type &&
                    level.tiles[i][j].type != -1) {
                    matchlength += 1;
                } else {
                    checkcluster = true;
                }
            }

            

            if (checkcluster) {
                if (matchlength >= 3) {
                    if (_.findIndex(scoreTypes, {id:level.tiles[i][j].type})>=0){
                      isScoreable = true;
                    }
                    clusters.push({ column: i, row:j+1-matchlength,
                                    length: matchlength, horizontal: false, isScoreable:isScoreable, scoreType: level.tiles[i][j].type });
                }

                matchlength = 1;
            }
        }
    }
  }

  const startGame = ()=>{
    paused = false;
    countDownTimer.start();
  }

  const newGame = (p5) => {    
    scoreTypes.map((el, i)=>{      
      scoreTypes[i].bubble = new Bubble(el.id, targetPoints, c[i], staron);  
      scoreTypes[i].bubble.setScore(targetPoints);
      scoreTypes[i].bubble.setX(level.x + 110 + (580/3)*i);
      scoreTypes[i].bubble.setY(15);
    });      
    p5.frameRate(60);

    countDownTimer = new CountDown(p5,level["difficult1"].time[paramLevel-1]);    
    countDownTimer.setPosition({x:level.x + 715, y:50})    
    gamestate = gamestates.ready;
    
    gameover = false;
    createLevel();
    findMoves();
    findClusters(); 
    if (document.getElementById("p5_loading_intro")) {
      document.getElementById("p5_loading_intro").style.display = "block";
      document.getElementById("p5_loading_intro").style.opacity = 1;
      anime({
        targets: document.getElementById("p5_loading_intro"),
        opacity:0,
        duration: 1000,
        delay:1000,
        easing: 'easeInOutQuad',   
        complete: (anim) => {
          document.getElementById("p5_loading_intro").style.display = "none";
        }
      });
    }

    if (!SettingsService.isTutorialAlreadyPlayed(1)) {      
      document.getElementById("game1Ayuda").style.display = "block";
      SettingsService.changeTutorialPlayed(1, true);
    } else {
      document.getElementById("game1Ayuda").style.display = "none";
      startGame();
    }      
    
  }

  const getRandomTile = () => {
    return Math.floor(Math.random() * tileimages.length);
  }

  const shiftTiles = () => {
    for (let i=0; i<level.columns; i++) {
        for (let j=level.rows-1; j>=0; j--) {
            if (level.tiles[i][j].type == -1) {
                level.tiles[i][j].type = getRandomTile();
            } else {
                let shift = level.tiles[i][j].shift;
                if (shift > 0) {
                    swap(i, j, i, j+shift)
                }
            }
            level.tiles[i][j].shift = 0;
        }
    }
  }

  const loopClusters = (func) => {
    const numClusters = clusters.length;
    for (let i = 0; i < numClusters; i++) {
      const cluster = clusters[i];
      const coffset = cluster.horizontal ? 1 : 0;
      const roffset = cluster.horizontal ? 0 : 1;
      const numTiles = cluster.length;
      for (let j = 0; j < numTiles; j++) {
        const tile = cluster[j];
        func(i, cluster.column + j * coffset, cluster.row + j * roffset, cluster);
      }
    }
  }

  const removeClusters = () => {
    loopClusters(function(index, column, row, cluster) { level.tiles[column][row].type = -1; });
    for (let i=0; i<level.columns; i++) {
        let shift = 0;
        for (let j=level.rows-1; j>=0; j--) {
            if (level.tiles[i][j].type == -1) {
                shift++;
                level.tiles[i][j].shift = 0;
            } else {
                level.tiles[i][j].shift = shift;
            }
        }
    }
}

  const resolveClusters = () => {
    findClusters();
    while (clusters.length > 0) {
        removeClusters();
        shiftTiles();
        findClusters();
    }
  }
  let done =  false;
  const createLevel = () => {
    fillRandoms();
    do {
        for (let i = 0; i < level.tiles.length; i++) {
            for (let j = 0; j < level.tiles[i].length; j++) {
                level.tiles[i][j].type = randoms[i * level.tiles[i].length + j];
            }
        }
        done = checkMoves();
    } while (!done);
    resolveClusters();
  };

  const checkMoves = () => {
      findMoves();
      return moves.length > 0;
  };
  
  
  const renderClusters = (p5) => {
    
    for (let i=0; i<clusters.length; i++) {
        let coord = getTileCoordinate(clusters[i].column, clusters[i].row, 0, 0);
        let direction = clusters[i].horizontal? "horizontal" : "vertical";

        if (clusters[i].isScoreable && (_.findIndex(flyingBulbs,{id:clusters[i].column+"-"+clusters[i].row})<0)){          
          for (let k=0; k<clusters[i].length; k++){
            let pos  = _.findIndex(scoreTypes, {id:clusters[i].scoreType});
            let flb = new FlyingBulb(
              clusters[i].column+"-"+clusters[i].row, 
              clusters[i].scoreType,
              direction==="horizontal" ? coord.tilex + ((level.tilewidth)*k):coord.tilex, 
              direction==="vertical" ? coord.tiley + ((level.tileheight)*k):coord.tiley, 
              tileimagesselect[clusters[i].scoreType], 
              scoreTypes[pos].bubble.x, 
              scoreTypes[pos].bubble.y, 
              level.tilewidth, 
              level.tileheight
            )
            flyingBulbs.push({id:clusters[i].column+"-"+clusters[i].row, bulb: flb});
          }
        } else {
           
          if (isFxEnabled && !playSound) { 
            const fx_1 = new Audio('/assets/fx_pop.mp3');
            fx_1.play(); 
            playSound=true;
            setTimeout(() =>playSound=false, 500)
          }

        }
        
    }
    
  }

  const getTileCoordinate = (column, row, columnoffset, rowoffset) => {
    let tilex = level.x + (column + columnoffset) * level.tilewidth - 10;
    let tiley = level.y + (row + rowoffset) * level.tileheight + 10;
    return { tilex: tilex, tiley: tiley};
  }


  const renderMoves = (p5) => {
    const coordCache = new Map();
    for (let i = 0; i < moves.length; i++) {
      const move = moves[i];
      let coord1 = coordCache.get(move) || getTileCoordinate(move.column1, move.row1, 0, 0);
      let coord2 = coordCache.get(move) || getTileCoordinate(move.column2, move.row2, 0, 0);
      if (!coordCache.has(move)) {
        coordCache.set(move, {coord1, coord2});
      }
      const {tilex: tilex1, tiley: tiley1} = coord1;
      const {tilex: tilex2, tiley: tiley2} = coord2;
      p5.stroke("#ff0000");
      p5.line(tilex1 + level.tilewidth/2, tiley1 + level.tileheight/2, tilex2 + level.tilewidth/2, tiley2 + level.tileheight/2);
    }
  }

  const shuffleTiles = () => {    
    const array = level.tiles.flatMap(row => row); // Use flatMap to create a flat array
    for (let i = array.length - 1; i > 0; i--) { // Fisher-Yates shuffle algorithm
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    let count = 0;
    level.tiles.forEach((row, i) => { // Use forEach to loop over the rows
        row.forEach((tile, j) => { // Use forEach to loop over the tiles in each row
            level.tiles[i][j] = array[count]; // Calculate the index from count
            count++;
        });
    });
  }

  const renderTiles = (p5) => {
    
    const tileCoords = [];

    for (let i = 0; i < level.columns; i++) {
      tileCoords[i] = [];
      for (let j = 0; j < level.rows; j++) {
        let shift = level.tiles[i][j].shift;
        let coord = getTileCoordinate(i, j, 0, (animationtime / animationtimetotal) * shift);
        tileCoords[i][j] = coord;
      }
    }

    for (let i = 0; i < level.columns; i++) {
      for (let j = 0; j < level.rows; j++) {
        let tile = level.tiles[i][j];
        let type = tile.type;
        let coord = tileCoords[i][j];
        
        if (type >= 0) {                
          drawTile(p5, coord.tilex, coord.tiley, type);
        }
        
        if (level.selectedtile.selected && level.selectedtile.column == i && level.selectedtile.row == j) {
          drawTileSelect(p5, coord.tilex, coord.tiley, type);
        }
      }
    }
    
    if (gamestate == gamestates.resolve && (animationstate == 2 || animationstate == 3)) {
        let shiftx = currentmove.column2 - currentmove.column1;
        let shifty = currentmove.row2 - currentmove.row1;
        let coord1 = getTileCoordinate(currentmove.column1, currentmove.row1, 0, 0);
        let coord1shift = getTileCoordinate(currentmove.column1, currentmove.row1, (animationtime / animationtimetotal) * shiftx, (animationtime / animationtimetotal) * shifty);
        let coord2 = getTileCoordinate(currentmove.column2, currentmove.row2, 0, 0);
        let coord2shift = getTileCoordinate(currentmove.column2, currentmove.row2, (animationtime / animationtimetotal) * -shiftx, (animationtime / animationtimetotal) * -shifty);
        drawTile(p5, coord1.tilex, coord1.tiley, -1);
        drawTile(p5, coord2.tilex, coord2.tiley, -1);
       if (animationstate == 2) {
          drawTileSelect(p5, coord1shift.tilex, coord1shift.tiley, level.tiles[currentmove.column1][currentmove.row1].type);
          drawTileSelect(p5, coord2shift.tilex, coord2shift.tiley, level.tiles[currentmove.column2][currentmove.row2].type);
        } else {
          drawTileSelect(p5, coord2shift.tilex, coord2shift.tiley, level.tiles[currentmove.column2][currentmove.row2].type);
          drawTileSelect(p5, coord1shift.tilex, coord1shift.tiley, level.tiles[currentmove.column1][currentmove.row1].type);
        }
    }
  }

  const drawTile = (p5, x, y, type) => {    
    if (type>=0){      
      p5.image(tileimages[type], x, y, level.tilewidth, level.tileheight);
    }else if (type==-1){
      p5.fill(59, 59, 59);
      p5.rect(x, y, level.tilewidth, level.tileheight, 30);
    }
  }

  const drawTileSelect = (p5, x, y, type) => {    
    if (type>=0 && tileimagesselect[type]) p5.image(tileimagesselect[type], x, y, level.tilewidth, level.tileheight);
  }

  const renderFlyingBulbs = (p5) => {    
    for (let i = flyingBulbs.length - 1; i >= 0; i--) {
        flyingBulbs[i].bulb.render(p5);
        if (flyingBulbs[i].bulb.isDie()) { 
          const pos = _.findIndex(scoreTypes, {id:flyingBulbs[i].bulb.btype});
          scoreTypes[pos].bubble.setScore(scoreTypes[pos].bubble.getScore() - 1);
          flyingBulbs.splice(i, 1);
        } 
    }
  }

  const update = (tframe) => {
    let dt = (tframe - lastframe) / 50;
    lastframe = tframe;    
    if (gamestate == gamestates.ready) {
        let sum = 0;
        scoreTypes.map((el, i)=>{ 
          if (el.bubble) sum += el.bubble.getScore();   
        });
        gameover = sum <= 0 ? true : false;
        if (aibot) {
            animationtime += dt;
            if (animationtime > animationtimetotal) {
                findMoves();
                //if (moves.length > 0) {
                if (sum > 0) { 
                    let move = moves[Math.floor(Math.random() * moves.length)];
                    mouseSwap(move.column1, move.row1, move.column2, move.row2);
                } else {
                    // No moves left, Game Over. We could start a new game.
                    // newGame();
                }
                animationtime = 0;
            }
        }
    } else if (gamestate == gamestates.resolve) {
        animationtime += dt;
        if (animationstate == 0) {
            if (animationtime > animationtimetotal) {
                findClusters();
                if (clusters.length > 0) {                    
                    removeClusters();
                    animationstate = 1;
                } else {
                    gamestate = gamestates.ready;
                }
                animationtime = 0;
            }
        } else if (animationstate == 1) {
            if (animationtime > animationtimetotal) {
                shiftTiles();
                animationstate = 0;
                animationtime = 0;
                findClusters();
                if (clusters.length <= 0) {
                    gamestate = gamestates.ready;
                }
            }
        } else if (animationstate == 2) {
            if (animationtime > animationtimetotal) {
                swap(currentmove.column1, currentmove.row1, currentmove.column2, currentmove.row2);
                findClusters();
                if (clusters.length > 0) {
                    animationstate = 0;
                    animationtime = 0;
                    gamestate = gamestates.resolve;
                } else {
                    animationstate = 3;
                    animationtime = 0;
                }
                findMoves();
                findClusters();
            }
        } else if (animationstate == 3) {
            if (animationtime > animationtimetotal) {
                swap(currentmove.column1, currentmove.row1, currentmove.column2, currentmove.row2);
                gamestate = gamestates.ready;
            }
        }
        
        findMoves();
        findClusters();
    }
  }

  
  const draw = (p5) => {
    p5.clear();
    update(p5.frameCount);
    

    

    
    p5.image(img_header, level.x + 60, 0);
    p5.image(img_tablero, level.x - 60, level.y - 30);
    
    countDownTimer.render(p5);

    if (countDownTimer.isEnded()) {
      gameover = true;
    }
    
    scoreTypes.forEach((el) => {
        el.bubble.render(p5);
    });

    renderTiles(p5);
    renderClusters(p5);
    renderFlyingBulbs(p5);

    if (showmoves && clusters.length === 0) {
        renderMoves(p5);
    }

    p5.image(img_planta_1, level.x - 100, p5.height - 200, img_planta_1.width / 1.5, img_planta_1.height / 1.5);
    p5.image(img_planta_2, level.x + levelwidth - 220, p5.height - 200);

    if (gameover && !completed) {
        completed = true;
        countDownTimer.pause();
        let stars = 0;
        scoreTypes.forEach((el) => {
            if (el.bubble.getFinished()) {
                stars++;
            }
        });
        fW.setStars(stars);
        setTimeout(() => {
            fW.setVisible();
        }, 500);
    }
    if (gameover) {
        fW.render(p5);
    }

    if (moves.length === 0) {
        shuffleTiles();
    }
  };

  const getMouseTile = (pos) => {
    let tx = Math.floor((pos.x - level.x) / level.tilewidth);
    let ty = Math.floor((pos.y - level.y) / level.tileheight);
    if (tx >= 0 && tx < level.columns && ty >= 0 && ty < level.rows) {
        return {
            valid: true,
            x: tx,
            y: ty
        };
    }
    return {
        valid: false,
        x: 0,
        y: 0
    };
  }

  const canSwap = (x1, y1, x2, y2) => {
    return x1 >= 0 && y1 >= 0 &&
           x2 >= 0 && y2 >= 0 &&
           x1 < level.columns && y1 < level.rows &&
           x2 < level.columns && y2 < level.rows &&
           ((Math.abs(x1 - x2) == 1 && y1 == y2) ||
            (Math.abs(y1 - y2) == 1 && x1 == x2));
  }

  const mousePressed = (p5, event) => {
    if (gameover) return;
    if (paused) return;
    let pos = {x:p5.mouseX/scale, y:p5.mouseY/scale}

    if (!drag) {
      let mt = getMouseTile(pos);

      if (mt.valid) {
          let swapped = false;
          if (level.selectedtile.selected) {
              if (mt.x == level.selectedtile.column && mt.y == level.selectedtile.row) {
                  level.selectedtile.selected = false;
                  drag = true;
                  return;
              } else if (canSwap(mt.x, mt.y, level.selectedtile.column, level.selectedtile.row)){
                  mouseSwap(mt.x, mt.y, level.selectedtile.column, level.selectedtile.row);
                  swapped = true;
              }
          }
          
          if (!swapped) {
              level.selectedtile.column = mt.x;
              level.selectedtile.row = mt.y;
              level.selectedtile.selected = true;
          }
      } else {
          level.selectedtile.selected = false;
      }
      drag = true;
    }
  }

  const mouseReleased = (p5, event) =>{
    drag = false;
  }

  function swiped(event) {
    if (drag && level.selectedtile.selected) {
      if (event.direction == 4) {
        if (canSwap(level.selectedtile.column+1, level.selectedtile.row, level.selectedtile.column, level.selectedtile.row)){
          mouseSwap(level.selectedtile.column+1, level.selectedtile.row, level.selectedtile.column, level.selectedtile.row);
        }
      } else if (event.direction == 8) {
        if (canSwap(level.selectedtile.column, level.selectedtile.row-1, level.selectedtile.column, level.selectedtile.row)){
          mouseSwap(level.selectedtile.column, level.selectedtile.row-1, level.selectedtile.column, level.selectedtile.row);
        }
      } else if (event.direction == 16) {
        if (canSwap(level.selectedtile.column, level.selectedtile.row+1, level.selectedtile.column, level.selectedtile.row)){
          mouseSwap(level.selectedtile.column, level.selectedtile.row+1, level.selectedtile.column, level.selectedtile.row);
        }
      } else if (event.direction == 2) {
        if (canSwap(level.selectedtile.column-1, level.selectedtile.row, level.selectedtile.column, level.selectedtile.row)){
          mouseSwap(level.selectedtile.column-1, level.selectedtile.row, level.selectedtile.column, level.selectedtile.row);
        }
      }
    }
  }

  const windowResized = (p5) => {    
    //scaleCanvas(p5);
  }

  const button1HandleClick = () => {
    document.getElementById("root").style.backgroundImage = "none";
    window.location.href = "/#/menu";    
  }

  const closeHelp = () => {
    document.getElementById("game1Ayuda").style.display = "none";
    startGame();
  }

  return (      
      <div className='Game1' id="Game1">
          <img id='game1Ayuda' onClick={closeHelp} src="ayudas/game1.png"/>
          <Sketch windowResized={windowResized} mousePressed={mousePressed} mouseReleased={mouseReleased} setup={setup} preload={preload} draw={draw} />          
      </div>
  )
  
}