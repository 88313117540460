import Matter from "matter-js";
import anime from 'animejs';

export default class Container {
    constructor(id, x, y, w, h, boxImg, _world) {
      const options = {
        label:"container_"+id,
        isStatic:true,
        restitution: 0.5
      };
      this.body = Matter.Bodies.rectangle(x, y, w, h, options);
      Matter.World.add(_world, this.body);
      this.w = w;
      this.h = h;
      this.scale = 1;
      this.boxImg = boxImg;
      this.anim = false;
    }    

    jiggle(){
      if (this.anim) return;
      this.anim = true;
      anime({
          targets: this,
          scale: 1.2,
          duration: 150,
          easing: 'easeInOutQuad',
          direction: 'alternate',
          complete: (anim) => {
              if (anim.completed) this.anim = false;
          }   
      });
    }
  
    show(p5) {
      const pos = this.body.position;
      p5.push();
      p5.translate(pos.x, pos.y);
      p5.scale(this.scale);
      p5.fill(255);      
      p5.rectMode(p5.CENTER);
      p5.imageMode(p5.CENTER);
      p5.image(this.boxImg, 0, 0, this.w, this.h);
      p5.pop();
    }
  }