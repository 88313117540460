
import AFRAME from 'aframe';
import video2 from '../../../../assets/video_2.webm';
import video3 from '../../../../assets/video_3.webm';
import video4 from '../../../../assets/video_4.webm';
import video5 from '../../../../assets/video_5.webm';
import video6 from '../../../../assets/video_6.webm';
import video7 from '../../../../assets/video_7.webm';
import video8 from '../../../../assets/video_8.webm';
import video9 from '../../../../assets/video_9.webm';
import Swal from 'sweetalert2';

AFRAME.registerComponent("fadei", {
    init: function() {
        // grab the "fading sphere"
        var fadingEl = document.querySelector("#lord-fader")
        // when clicked - emit the defined "startEvent"
        this.el.addEventListener("click", e => fadingEl.emit("animate"))
    }
})

AFRAME.registerComponent("startvideo", {
    init: function() {
        console.log("start video")
        /*Swal.fire({
                title: '¡La bombilla se ha fundido!',
                text: 'Busca dónde puedes reciclarla.',
                icon: 'info',
                confirmButtonText: 'Ok' 
            });*/
        
    }
})

AFRAME.registerComponent("hotspot1", {
    init: function() {
        this.el.addEventListener("click", (e)=>{  
            console.log("video2")          
            var entity = this.el;
            document.querySelector("#lord-fader").emit("fadein");
            document.querySelector("#hotspot1").object3D.visible = false;
            entity.parentNode.removeChild(entity);
            setTimeout(()=>{                
                document.querySelector("#videosphere").object3D.rotation.y = 0;
                document.querySelector("#video").src = video2;
                document.querySelector("#video").loop = false;
                document.querySelector("#hotspot2").object3D.visible = true;
                document.querySelector("#hotspot3").object3D.visible = true;
                document.querySelector("#video").play();
                setTimeout(()=>{ 
                    document.querySelector("#lord-fader").emit("fadeout");
                },500);
            },1000);
        })
    }
})

AFRAME.registerComponent("hotspot2", {
    init: function() {
        this.el.addEventListener("click", (e)=>{            
            Swal.fire({
                title: '¡La bombilla se ha fundido!',
                text: 'Busca dónde puedes reciclarla.',
                icon: 'info',
                confirmButtonText: 'Ok' 
            });
        })
    }
})

AFRAME.registerComponent("hotspot3", {
    init: function() {
        this.el.addEventListener("click", (e)=>{            
            var entity = this.el;
            console.log("video3")          
            document.querySelector("#lord-fader").emit("fadein");
            document.querySelector("#hotspot2").object3D.visible = false;
            document.querySelector("#hotspot3").object3D.visible = false;
            entity.parentNode.removeChild(entity);
            setTimeout(()=>{                
                document.querySelector("#videosphere").object3D.rotation.y = 0;
                document.querySelector("#video").src = video3;
                document.querySelector("#video").loop = false;
                document.querySelector("#hotspot4").object3D.visible = true;
                document.querySelector("#video").play();
                setTimeout(()=>{ 
                    document.querySelector("#lord-fader").emit("fadeout");
                },500);
            },1000);
        })
    }
})


AFRAME.registerComponent("hotspot4", {
    init: function() {
        this.el.addEventListener("click", (e)=>{            
            var entity = this.el;
            console.log("video4")          
            document.querySelector("#hotspot4").object3D.visible = false;
            entity.parentNode.removeChild(entity);
            document.querySelector("#hotspot6").object3D.visible = true;
            document.querySelector("#videosphere").object3D.rotation.y = 0;
            document.querySelector("#video").src = video4;
            document.querySelector("#video").loop = false;
            document.querySelector("#hotspot5").object3D.visible = true;
            document.querySelector("#video").play();
        })
    }
})

AFRAME.registerComponent("hotspot5", {
    init: function() {
        this.el.addEventListener("click", (e)=>{            
            Swal.fire({
                title:"¿Estás seguro?", 
                icon: "question",
                text: "Confirma si quieres tirar la bombilla a la basura",
                showDenyButton: true,                
                confirmButtonText: 'Si estoy seguro',
                denyButtonText: `Seguir buscando`,
            }).then((result) => {
                if (result.isConfirmed) {
                    
                } else if (result.isDenied) {
                    window.addStar();
                }
                
            });
            
        })
    }
})

/*
AFRAME.registerComponent("hotspot5", {
    init: function() {
        this.el.addEventListener("click", (e)=>{            

            swal
            ("¡No lo hagas!", "Si tiras la bombilla a la basura no la estás reciclando. Busca el contenedor adecuado.", "warning")
            .then((willDelete) => {
                window.addStar();
            });
            
        })
    }
})
*/

AFRAME.registerComponent("hotspot6", {
    init: function() {
        this.el.addEventListener("click", (e)=>{            
            var entity = this.el;
            console.log("video5")          
            document.querySelector("#lord-fader").emit("fadein");
            document.querySelector("#hotspot5").object3D.visible = false;
            entity.parentNode.removeChild(entity);
            setTimeout(()=>{                
                document.querySelector("#videosphere").object3D.rotation.y = 0;
                document.querySelector("#video").src = video5;
                document.querySelector("#video").loop = false;
                document.querySelector("#hotspot7").object3D.visible = true;
                document.querySelector("#video").play();
                setTimeout(()=>{ 
                    document.querySelector("#lord-fader").emit("fadeout");
                },500);
            },1000);
        })
    }
})

AFRAME.registerComponent("hotspot7", {
    init: function() {
        this.el.addEventListener("click", (e)=>{            
            var entity = this.el;
            console.log("video5")          
            document.querySelector("#lord-fader").emit("fadein");
            entity.parentNode.removeChild(entity);
            setTimeout(()=>{                
                document.querySelector("#videosphere").object3D.rotation.y = 0;
                document.querySelector("#video").src = video6;
                document.querySelector("#video").loop = false;
                document.querySelector("#hotspot88").object3D.visible = true;
                document.querySelector("#video").play();
                setTimeout(()=>{ 
                    document.querySelector("#lord-fader").emit("fadeout");
                },500);
            },1000);
        })
    }
})

AFRAME.registerComponent("hotspot88", {
    init: function() {
        this.el.addEventListener("click", (e)=>{            
            var entity = this.el;
            console.log("video7")          
            entity.parentNode.removeChild(entity);
            document.querySelector("#videosphere").object3D.rotation.y = 0;
            document.querySelector("#video").src = video7;
            document.querySelector("#video").loop = false;
            document.querySelector("#hotspot8").object3D.visible = true;
            document.querySelector("#hotspot9").object3D.visible = true;
            document.querySelector("#video").play();
        })
    }
})

AFRAME.registerComponent("hotspot8", {
    init: function() {
        this.el.addEventListener("click", (e)=>{   
            Swal.fire({
                title:"¿Estás seguro?", 
                icon: "question",
                text: "Confirma si quieres tirar la bombilla al contenedor de vidrio",
                showDenyButton: true,                
                confirmButtonText: 'Si estoy seguro',
                denyButtonText: `Seguir buscando`,
            }).then((result) => {
                if (result.isConfirmed) {
                    
                } else if (result.isDenied) {
                    window.addStar();
                }
                
            });
        })
    }
})

AFRAME.registerComponent("hotspot9", {
    init: function() {
        this.el.addEventListener("click", (e)=>{            
            var entity = this.el;
            console.log("video8")          
            document.querySelector("#lord-fader").emit("fadein");
            document.querySelector("#hotspot8").object3D.visible = false;
            entity.parentNode.removeChild(entity);
            setTimeout(()=>{                
                document.querySelector("#videosphere").object3D.rotation.y = 0;
                document.querySelector("#video").src = video8;
                document.querySelector("#video").loop = false;
                document.querySelector("#hotspot10").object3D.visible = true;
                document.querySelector("#video").play();
                setTimeout(()=>{ 
                    document.querySelector("#lord-fader").emit("fadeout");
                },500);
            },1000);
        })
    }
})

AFRAME.registerComponent("hotspot10", {
    init: function() {
        this.el.addEventListener("click", (e)=>{            
            var entity = this.el;
            console.log("video9")          
            entity.parentNode.removeChild(entity);
            document.querySelector("#hotspot11").object3D.visible = true;
            document.querySelector("#videosphere").object3D.rotation.y = 0;
            document.querySelector("#video").src = video9;
            document.querySelector("#video").loop = false;
            document.querySelector("#video").play();
        })
    }
})

AFRAME.registerComponent("hotspot11", {
    init: function() {
        this.el.addEventListener("click", (e)=>{            
            Swal.fire({
                title:"¿Estás seguro?", 
                icon: "question",
                text: "Confirma si quieres tirar la bombilla al contenedor de Ambilamp",
                showDenyButton: true,                
                confirmButtonText: 'Si estoy seguro',
                denyButtonText: `Seguir buscando`,
            }).then((result) => {
                if (result.isConfirmed) {
                    window.addStar();
                    Swal.fire({
                        title:"¡Enhorabuena!", 
                        icon: "success",
                        text: "Has encontrado el contenedor Ambilamp donde reciclar bombillas.",
                        confirmButtonText: 'Ok',
                    }).then((result) => {
                        window.feedbackWindow();
                    });
                } else if (result.isDenied) {
                    Swal.fire({
                        title:"¡Lo sentimos!", 
                        icon: "error",
                        text: "Realmente has encontrado el contenedor Ambilamp donde reciclar bombillas",
                        confirmButtonText: 'Ok',
                    }).then((result) => {
                        window.feedbackWindow();
                    });   
                }
                
            });
                    
        })
    }
})