import Timer from "./Timer";

export default class CountDown {

    constructor(p5, time) {
        this.xTextPos = 60;
        this.yTextPos = 60;
        this.simpleTimer = new Timer(p5,time);        
        this.end = false;
    }

    start(){
        this.simpleTimer.start();
    }

    setPosition(pos){
        this.xTextPos = pos.x;
        this.yTextPos = pos.y;
    }

    isEnded() {
        return this.end;
    }

    pause(){
        this.simpleTimer.pause();
    }

    formatTime(time) {
        var sec_num = parseInt(time, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
    
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return minutes+':'+seconds;
    }    

    render(p5) {
        p5.push();   
            p5.fill("#5f7e0a");
            p5.noStroke();     
            p5.rect(this.xTextPos-50, this.yTextPos-35, 100, 45, 10);            
            p5.textAlign(p5.CENTER);        
            p5.textFont('FinkHeavy');
            p5.textSize(35);
            p5.fill("white");        
            this.updateTimer(p5);
        p5.pop();
        
    }

    updateTimer(p5) {
        if( this.simpleTimer.expired() ) {
            p5.text( this.formatTime(0), this.xTextPos, this.yTextPos );
            this.end = true;
        }
        else {
            p5.text( this.formatTime(Math.round(this.simpleTimer.getRemainingTime()/1000)), this.xTextPos, this.yTextPos )
        }
    }

}

