import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import "./Game4.css";
import 'aframe';
import 'babel-polyfill';
import anime from 'animejs';
import {Entity, Scene} from 'aframe-react';
import ReactDOM from 'react-dom';
import "./components/game4/aframe.js";
import Settings from "../blocks/common/Settings";
import SettingsService from "../../services/settings";
import video1 from '../../assets/video_1.webm';
import video2 from '../../assets/video_2.webm';
import video3 from '../../assets/video_3.webm';
import video4 from '../../assets/video_4.webm';
import video5 from '../../assets/video_5.webm';
import video6 from '../../assets/video_6.webm';
import video7 from '../../assets/video_7.webm';
import video8 from '../../assets/video_8.webm';
import video9 from '../../assets/video_9.webm';
import hotspot from '../../assets/hotspot.png';
import info from '../../assets/info.png';
import steps from '../../assets/steps.png';
import question from '../../assets/question.png';
import Sketch from "react-p5";
import feedbackWindow from './components/feedbackWindowGame4';

export default function Game4(props) {  
  
  
  let gameSize = {w:960,h:640};
  let stageSize = 700;
  let scale = 1;  
  let fW;
  window.stars = 0;
  let level = gameSize.w/2 - stageSize/2;
  
  let isMusicEnabled = SettingsService.isMusicEnabled();
  let isFxEnabled = SettingsService.isFxEnabled();
  let difficulty = SettingsService.getDifficulty();

  useEffect(() => {
    const canvasRatio = gameSize.w / gameSize.h;
    const windowRatio = window.innerWidth / window.innerHeight;
    if (windowRatio > canvasRatio) {  
      scale = window.innerHeight / gameSize.h
    } else {
      scale = window.innerWidth / gameSize.w;
    }
    window.scale = scale;
    document.getElementById("homeButton").style.left = level + 50 + "px";   
    document.getElementById("homeButton").style.top = "8vh";   
    document.getElementById("homeButton").style.transform = "translate(-50%, -50%) scale("+scale+")";   
    document.getElementById("Settings").style.bottom = "5vh";
    document.getElementById("Settings").style.left = level + 50 + "px";   
    document.getElementById("Settings").style.transform = "translate(-50%, -50%) scale("+scale+")"; 
    document.getElementById("header").style.transform = "translateX(-50%) scale("+scale+")"; 
    document.getElementById("CanvasGame4").style.transform = "translate(-50%, -50%) scale("+scale+")"; 
    document.getElementById("CanvasGame4").style.display = "none"; 

    if (document.getElementById("p5_loading_intro")) {
      document.getElementById("p5_loading_intro").style.display = "block";
      document.getElementById("p5_loading_intro").style.opacity = 1;
      anime({
        targets: document.getElementById("p5_loading_intro"),
        opacity:0,
        duration: 1000,
        delay:1000,
        easing: 'easeInOutQuad',   
        complete: (anim) => {
          document.getElementById("p5_loading_intro").style.display = "none";
        }
      });
    }
    
  }, []);

  const button1HandleClick = (event) => {
    event.preventDefault();
    window.location.href = "/#/menu";    
  }

  const musicEnabledChanged = () => {
    isMusicEnabled = SettingsService.isMusicEnabled();

    let music = document.getElementById("music");
    if (music) {
      isMusicEnabled ? music.play() : music.pause();
    }
  }

  window.feedbackWindow = () => {
    document.getElementById("CanvasGame4").style.display = "block"; 
    fW.setStars(window.stars);
    fW.setVisible();
  }

  window.addStar = () => {
    if (window.stars>=3) return;
    window.stars += 1;
    const fx_1 = new Audio('/assets/fx_star.mp3');
    if (isFxEnabled) {
      fx_1.play();  
    }

    if (window.stars>=1) document.getElementById("star1").src = "/assets/game1/star.png";
    if (window.stars>=2) document.getElementById("star2").src = "/assets/game1/star.png";
    if (window.stars>=3) document.getElementById("star3").src = "/assets/game1/star.png";

  }

  const fxEnabledChanged = () => {
    isFxEnabled = SettingsService.isFxEnabled();
  }

  const difficultyChanged = () => {
    difficulty = SettingsService.getDifficulty();
  }

  const scaleCanvas = (p5) => {
    scale = (window.innerHeight+15) / p5.height;
    document.getElementById("CanvasGame4").style.transform = "translate(-50%, -50%) scale("+scale+")";        
  }

  const preload = (p5) => {

  }

  const draw = (p5) => {
    if (document.getElementById("CanvasGame4").style.display=="block") fW.render(p5);
  }

  const changeLevelCallback = (level) => {
    //paramLevel = level;
  }

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(gameSize.w, gameSize.h).parent(canvasParentRef);             
    fW = new feedbackWindow(p5, "Game4", "CanvasGame4", p5.width/2, p5.height/2, scale, changeLevelCallback);
    fW.setLevel(1);
    /*setTimeout(()=> {
      window.stars = 3;
      window.feedbackWindow()
      scaleCanvas(p5);      
    },5000)*/
    
  }

  return (
    <div className='Game4' id="Game4">        
        <button id="homeButton" style={{backgroundImage:"url(/assets/game1/btn_home.png)"}} onClick={button1HandleClick}></button>        
        <header id="header" style={{backgroundImage:"url(/assets/game4/header.png)"}}>
          <div className="line"></div>
          <div className="stars">
            <img id="star1" src={"/assets/game3/staroff.png"}></img>
            <img id="star2" src={"/assets/game3/staroff.png"}></img>
            <img id="star3" src={"/assets/game3/staroff.png"}></img>
          </div>
        </header>

        <div className='CanvasGame4' id="CanvasGame4">
          <Sketch setup={setup} preload={preload} draw={draw} />          
        </div>

        <Settings musicEnabledChanged={musicEnabledChanged} fxEnabledChanged={fxEnabledChanged} difficultyChanged={difficultyChanged}></Settings> 
        <Scene loading-screen="dotsColor: white; backgroundColor: #c0d58a" cursor="rayOrigin: mouse"  vr-mode-ui="enabled: false" raycaster="objects: .raycastable" >
        <a-assets>
          <img id="hotspot" src={hotspot}></img>
          <img id="steps" src={steps}></img>
          <img id="info" src={info}></img>
          <img id="question" src={question}></img>
          <video id="video" preload="auto"
               src={video1}
               loop={false}
               crossOrigin="anonymous"></video>
          <video id="video2" preload="auto"
               src={video2}
               loop={false}
               crossOrigin="anonymous" ></video>
          <video id="video3" preload="auto"
               src={video3}
               loop={false}
               crossOrigin="anonymous" ></video>
          <video id="video4" preload="auto"
               src={video4}
               loop={false}
               crossOrigin="anonymous" ></video>
          <video id="video5" preload="auto"
               src={video5}
               loop={false}
               crossOrigin="anonymous" ></video>
          <video id="video6" preload="auto"
               src={video6}
               loop={false}
               crossOrigin="anonymous" ></video>
          <video id="video7" preload="auto"
               src={video7}
               loop={false}
               crossOrigin="anonymous" ></video>
          <video id="video8" preload="auto"
               src={video8}
               loop={false}
               crossOrigin="anonymous" ></video>
          <video id="video9" preload="auto"
               src={video9}
               loop={false}
               crossOrigin="anonymous" ></video>
        </a-assets>
                
        <Entity hotspot1 primitive="a-image"
          id="hotspot1"
          position="-8 0 -2.3"
          rotation="0 90 0"
          scale="0.8 0.8 0.8"
          src = "#hotspot"
          class="raycastable"
        ></Entity>

        <Entity hotspot2 primitive="a-image"
          id="hotspot2"
          position="-8 0 -2.3"
          rotation="0 90 0"
          scale="0.8 0.8 0.8"
          src = "#info"
          class="raycastable"
          visible="false"
        ></Entity>

        <Entity hotspot3 primitive="a-image"
          id="hotspot3"
          position="4.5 -1.5 2"
          rotation="75 0 0"
          scale="1 1 1"
          src = "#steps"
          class="raycastable"
          visible="false"
        ></Entity>

        <Entity hotspot4 primitive="a-image"
          id="hotspot4"
          position="-3 -4.5 3"
          rotation="0 -20 0"
          scale="0.8 0.8 0.8"
          src = "#hotspot"
          class="raycastable"
          visible="false"
        ></Entity>

        <Entity hotspot5 primitive="a-image"
          id="hotspot5"
          position="-3 -2.5 3"
          rotation="0 180 0"
          scale="0.8 0.8 0.8"
          src = "#question"
          class="raycastable"
          visible="false"
        ></Entity>

        <Entity hotspot6 primitive="a-image"
          id="hotspot6"
          position="6.5 -2.6 -8.5"
          rotation="35 -30 0"
          scale="1 1 1"
          src = "#steps"
          class="raycastable"
          visible="false"
        ></Entity>

        <Entity hotspot7 primitive="a-image"
          id="hotspot7"
          position="-14 -3.5 -8"
          rotation="45 -90 0"
          scale="1.8 1.8 1.8"
          src = "#steps"
          class="raycastable"
          visible="false"
        ></Entity>

        <Entity hotspot88 primitive="a-image"
          id="hotspot88"
          position="-5 0 -3"
          rotation="0 90 0"
          scale="0.8 0.8 0.8"
          src = "#hotspot"
          class="raycastable"
          visible="false"
        ></Entity>

        <Entity hotspot8 primitive="a-image"
          id="hotspot8"
          position="-5 0 -3"
          rotation="0 90 0"
          scale="0.8 0.8 0.8"
          src = "#question"
          class="raycastable"
          visible="false"
        ></Entity>

        <Entity hotspot9 primitive="a-image"
          id="hotspot9"
          position="10 -2 -8"
          rotation="35 0 0"
          scale="2.5 1.5 1.5"
          src = "#steps"
          class="raycastable"
          visible="false"
        ></Entity>

        <Entity hotspot10 primitive="a-image"
          id="hotspot10"
          position="-2 -1 -6"
          rotation="0 0 0"
          scale="0.8 0.8 0.8"
          src = "#hotspot"
          class="raycastable"
          visible="false"
        ></Entity>

        <Entity hotspot11 primitive="a-image"
          id="hotspot11"
          position="-2 0 -6"
          rotation="0 0 0"
          scale="0.8 0.8 0.8"
          src = "#question"
          class="raycastable"
          visible="false"
        ></Entity>
        
        <Entity startvideo id="videosphere" primitive="a-videosphere"
          rotation="0 -90 0"
          src="#video"
        ></Entity>        

        <Entity primitive="a-camera" position="0 0 -1.5">
              <a-sphere id="lord-fader" radius="0.05" 
              material="shader:flat; color: #c0d58a; opacity: 0.0; side: double " 
              animation="property: material.opacity; from: 0.0; to: 1.0 dur: 1000; loop: 1; startEvents: fadein"
              animation__2="property: material.opacity; from: 1.0; to: 0.0 dur: 1000; loop: 1; startEvents: fadeout"
            ></a-sphere>
        </Entity>

      </Scene>
    </div>
  )
  
}