import React, { useEffect } from 'react';
import _ from 'lodash';

import "./VideoWindow.css";

export const showVideo = (videoId, scale) => {

    document.getElementById('videoContainer').style.transform  = `scale(${scale})`;
    let video = document.getElementsByTagName('video')[0];
    video.src = `videos/video${videoId}.mp4`;
    video.load();

    video.oncanplay = () => {
        document.getElementById('videoWindow').style.display = 'block';
    }

    let music = document.getElementById("music");
    if (music) {
        music.volume = 0;
    }

}

export default function VideoWindow(props) {

    useEffect( () => {
        const height = 537;
        document.getElementById('videoContainer').style.marginTop = (window.innerHeight - height) / 2 + 'px';

     }, []);

    const closeVideo = () => { 
        
        document.getElementById('videoWindow').style.display = 'none';
        let video = document.getElementsByTagName('video')[0];
        video.src = '';

        let music = document.getElementById("music");
        if (music) {
            music.volume = 0.5;
        }

    }

    return (
        <div id="videoWindow" style={{"display": "none"}} onClick={ (e) => { e.stopPropagation(); } }>
            <div id="videoContainer" style={{backgroundImage: 'url(assets/general/videowindow.png)'}}>
            <video onEnded={closeVideo} autoPlay playsInline></video>
            <button id="skipButton" onClick={closeVideo} style={{backgroundImage: 'url(assets/general/btn_close.png)'}}></button>
            </div>
        </div>
    );

}
    