import anime from 'animejs';

import SettingsService from "../../../../services/settings";

export default class Tile {
    constructor(id, tileType, image, x, y, w, h, star, fixedimage) {
        this.fx_1 = new Audio('/assets/fx_correct.mp3');
        this.id = id;
        this.tileType = tileType;
        this.fixedimage = fixedimage;
        this.image = image;
        this.star = star;    
        this.anterior = 0;    
        this.x = x;
        this.y = y;    
        this.w = w;
        this.h = h;    
        this.newH = h;
        this.bubble = undefined;
        this.moving = false;
        this.selected = false;     
        this.inPlace = false;   
        this.inPlaceTime = 0;
        this.die = false;    
        this.ratio = 1;
        if (this.tileType==2 || this.tileType==3) this.withStar = true;
        else this.withStar = false;
        this.starX = this.x+(this.w/2)-(this.w/6);
        this.starY = this.y+(this.h/2)-(this.h/6);
        this.isFxEnabled = SettingsService.isFxEnabled();
    }

    getX() { return this.x; }
    getY() { return this.y; }
    getTileType() { return this.tileType; }

    setTileType(newType, image) { 
        this.tileType=newType;
        this.image=image;
    }

    playSound(){        
        if (this.isFxEnabled) {
            this.fx_1.play();
        }
    }

    checkSound(value){
        
        if (!this.anterior && value) this.inPlaceTime = 0;
        if (value!==this.anterior) {this.anterior = value;}

        this.inPlaceTime++;
        if (this.inPlaceTime===1) {
            if (this.inPlace) this.playSound();
            this.inPlaceTime++;
        }
    }

    setInPlace(value) {
        this.inPlace = value;   
        

        
        //console.log("inplace",this.id,this.inPlaceTime)
    }

    setResize(x, y, w, h) { 
        this.x = x;
        this.y = y;    
        this.w = w;
        this.h = h;   
        this.starX = this.x+(this.w/2)-(this.w/6);
        this.starY = this.y+(this.h/2)-(this.h/6);
    }

    select() {
        this.selected = true;
    }

    deselect() {
        this.selected = false;
    }

    isMoving() { 
        return this.moving; 
    }

    hasStar(){
        return this.withStar;
    }

    activeStars(bubble, xfin, yfin){  
        if (this.moving) return false;  
        if (!this.inPlace) return false;
        this.bubble = bubble;
        this.moving = true;    
        anime({
            targets: this,
            starX: xfin,
            starY: yfin,
            duration: 500,
            easing: 'easeInOutQuad',
            delay: Math.random()*500,
            complete: (anim) => {
                if (anim.completed) {
                    this.die = true;
                    this.moving = false;
                    if (this.bubble) this.bubble.setScore(1);
                }
            }            
        });
        return true;
    }

    move(x,y){
        this.moving = true;
        this.inPlaceTime =0;
        anime({
            targets: this,
            x: x,
            y: y,
            starX : x+(this.w/2)-(this.w/6),
            starY : y+(this.h/2)-(this.h/6),
            duration: 60,
            easing: 'linear',
            complete: (anim) => {
                if (anim.completed) {
                    this.moving = false;
                    this.selected = false;
                }
            }            
        });
    }

    displayMovingImage(p5){        
        if (!this.image) return;
        if (this.tileType===1 || this.tileType===2 )
            p5.image(this.image, this.x, this.y, this.w, this.h, 0, (this.h/2)-p5.frameCount%(this.h/2), this.w, this.h/this.ratio);              
        else if (this.tileType===3 || this.tileType===4 )
            p5.image(this.image, this.x, this.y, this.w, this.h, (this.w/2)-p5.frameCount%(this.w/2), 0, this.w/this.ratio, this.h);  
        else if (this.tileType===8 || this.tileType===7 || this.tileType===5 || this.tileType===6 )
            p5.image(this.image, this.x, this.y, this.w, this.h, (this.w/2)-p5.frameCount%(this.w/2), 0, this.w/this.ratio, this.h);  
        else
            p5.image(this.image, this.x, this.y, this.w, this.h);    
            
        this.checkSound(true);
    }

    displayImage(p5){        
        if (!this.image) return;
        if (this.tileType===1 || this.tileType===2 )
            p5.image(this.image, this.x, this.y, this.w, this.h, 0, (this.h/2), this.w, this.h/this.ratio);              
        else if (this.tileType===3 || this.tileType===4 )
            p5.image(this.image, this.x, this.y, this.w, this.h, (this.w/2), 0, this.w/this.ratio, this.h);  
        else if (this.tileType===8 || this.tileType===7 || this.tileType===5 || this.tileType===6 )
            p5.image(this.image, this.x, this.y, this.w, this.h, (this.w/2), 0, this.w/this.ratio, this.h);  
        else
            p5.image(this.image, this.x, this.y, this.w, this.h);    
            
        this.checkSound(false);
    }

    displaySelected(p5){
        if (!this.selected) return;
        p5.noStroke();
        p5.fill(p5.color(255, 255, 255, 102));     
        p5.rect(this.x, this.y, this.w, this.h);
    }

    displayStar(p5){        
        p5.push();
        p5.translate(this.starX, this.starY);
        p5.image(this.star, 0, 0, this.w/3, this.h/3); 
        p5.pop();
    }

    displayFixed(p5){   
        if (!this.fixedimage) return;     
        p5.image(this.fixedimage, this.x, this.y, this.w, this.h); 
    }
  
    render(p5) {     
        if (this.tileType>=0){    
            if (!this.inPlace) this.displayImage(p5);     
            else this.displayMovingImage(p5);                                        
            this.displayFixed(p5);
            this.displaySelected(p5);
            if (this.withStar && !this.die) this.displayStar(p5);    
        }
    }
  
}