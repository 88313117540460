import _ from 'lodash';
import anime from 'animejs';

import SettingsService from "../../../services/settings";

export default class feedbackWindow {

    constructor(p5, id, x, y, scale, changeLevelCallback) {
      this.image_fw = undefined;
      this.x = x;
      this.y = y;
      this.w = 785;
      this.h = 558;
      this.scale = scale;
      this.level = 0;
      this.gameId  = id;
      this.show = false;
      // TODO: Only for testing. Remove
      //this.show = true;
      this.staron = undefined;
      this.tree = undefined;
      this.tv = undefined;
      this.staroff = undefined;
      this.btn1 = undefined;
      this.btn2 = undefined;
      this.btn3 = undefined;
      this.alpha1 = 0;
      this.alpha2 = 0;
      this.alpha3 = 0;
      this.scale1 = 0;
      this.scale2 = 0;
      this.scale3 = 0;
      this.numStars = 0;
      p5.loadImage('/assets/general/tree.png', img => {
        this.tree = img
      });
      p5.loadImage('/assets/general/tv.png', img => {
        this.tv = img
      });
      p5.loadImage('/assets/general/btn_home.png', img => {
        this.btn1 = img
      });
      p5.loadImage('/assets/general/btn_restart.png', img => {
        this.btn2 = img
      });
      p5.loadImage('/assets/general/btn_next.png', img => {
        this.btn3 = img
      });
      p5.loadImage('/assets/general/star_off.png', img => {
        this.staroff = img
      });
      p5.loadImage('/assets/general/star_on.png', img => {
        this.staron = img
      });
      p5.loadImage('/assets/general/feedbackwin.png', img => {
        this.image_fw = img
      });

      let posx = p5.width/2 - 80;      
      this.button1 = p5.createButton("");
      this.button1.position(posx-95, this.y+150);
      this.button1.id("btn1");
      this.button1.parent(this.gameId);
      this.button1.class("generalBtn");
      this.button1.style("background-image","url(/assets/general/btn_home.png)");
      this.button1.style("display","none");
      this.button1.style("z-index","99999999");
      this.button1.mousePressed(this.btn1Handler);
      this.button2 = p5.createButton("");
      this.button2.position(posx+40, this.y+150);
      this.button2.id("btn2");
      this.button2.parent(this.gameId);
      this.button2.class("generalBtn");
      this.button2.style("background-image","url(/assets/general/btn_restart.png)");
      this.button2.style("display","none");
      this.button2.style("z-index","99999999");
      this.button2.mousePressed(this.btn2Handler);
      this.button3 = p5.createButton("");
      this.button3.position(posx+170, this.y+150);
      this.button3.id("btn3");
      this.button3.parent(this.gameId);
      this.button3.class("generalBtn");
      this.button3.style("background-image","url(/assets/general/btn_next.png)");
      this.button3.mousePressed(this.btn3Handler);
      this.button3.style("display","none");   
      this.button3.style("z-index","99999999");

      document.getElementById("overlay_left").style.display = "none";
      document.getElementById("overlay_right").style.display = "none";

      this.changeLevelCallback = changeLevelCallback;

      // TODO: Only for testing. Remove
      //this.level=1;
      //this.setVisible();


      this.isFxEnabled = SettingsService.isFxEnabled();
        
    }

    btn1Handler() {
      document.getElementById("root").style.backgroundImage = "none";
      window.location.href = "/#/menu";   
    }

    btn2Handler() {
      window.location.reload();   
    }

    btn3Handler = () => {
      this.level ++;
      let game = this.gameId.toLowerCase()
      //console.log(this.gameId, "/#/"+game+"/"+this.level);
      if (this.level>4) {
        window.location.href = "/#/menu"; 
      } else { 
        window.location.href = "/#/"+game+"/"+this.level;
        this.changeLevelCallback(this.level);
        window.location.reload(); 
      }        
    }

    setVisible(){
      this.button1.style("display","block");
      this.button2.style("display","block");
      this.button3.style("display","block");
      
      let wl = window.innerWidth - (this.x*2*this.scale);

      document.getElementById("overlay_left").style.display = "block";
      document.getElementById("overlay_right").style.display = "block";
      
      document.getElementById("overlay_left").style.width = wl/2 + "px";
      document.getElementById("overlay_right").style.width = wl/2 + "px";

      this.alpha1 = 0;
      this.alpha2 = 0;
      this.alpha3 = 0;
      this.scale1 = 0;
      this.scale2 = 0;
      this.scale3 = 0;
      this.show = true;
      this.saveStatus();
      if (this.numStars>0){
        const fx_1 = new Audio('/assets/fx_window.mp3');
        if (this.isFxEnabled) {
          fx_1.play();
        }
      }else{
        const fx_2 = new Audio('/assets/fx_window_nopoints.mp3');
        if (this.isFxEnabled) {
          fx_2.play();
        }
      }
      this.jiggle();
    }

    saveStatus(){
      
      let game = this.gameId.toLowerCase()
      let gameId = parseInt(this.gameId.replace("Game",""));      
      let nextGameId = gameId + 1;

      let stars = SettingsService.get('stars');
      stars += this.numStars;
      SettingsService.set('stars', stars);

      let trees = SettingsService.get('trees');
      trees += this.numStars*10;
      SettingsService.set('trees', trees);

      if (this.numStars>0) {

        let currentGame = SettingsService.get(game);
        currentGame.level = parseInt(this.level);      
        currentGame.stars[(this.level-1)] = this.numStars;
        SettingsService.set(game, currentGame);

        let nextGame = SettingsService.get(`game${nextGameId}`);
        if (nextGame && nextGame.status<1) { 
          nextGame.status = 1;
          SettingsService.set(`game${nextGameId}`, nextGame);
        }
 
      }
          
    }

    setLevel(level){
      this.level = parseInt(level);
    }

    setStars(stars){
      this.numStars = stars;
    }

    displayTexts(p5){
      let posx = p5.width/2 - 50;
      p5.push();   
        p5.textAlign(p5.LEFT);      
        p5.textFont('FinkHeavy');
        p5.textSize(30);
        p5.fill("black"); 
        p5.text( "TU AYUDA EQUIVALE |", posx-200, this.y-100);       
        p5.text( (this.numStars*10), posx+100, this.y-100);       
        p5.text( (this.numStars*100) + "h", posx+180, this.y-100);       
        p5.image(this.tree, posx+140, this.y-124); 
        p5.image(this.tv, posx+260, this.y-122); 
      p5.pop();
    }

    displayTextLevel(p5){
      let posx = p5.width/2;
      p5.push();   
        p5.textAlign(p5.CENTER); 
        p5.textFont('FinkHeavy');
        p5.textSize(180);
        p5.fill("black"); 
        p5.text( this.level,  posx, this.y+60);       
      p5.pop();
    }

    jiggle(){
      if (this.numStars>=1)
      anime({
          targets: this,
          alpha1: 255,
          scale1: 1,
          duration: 200,
          delay:400,
          easing: 'easeInOutQuad',          
          complete: (anim) => {
            const fx_1 = new Audio('/assets/fx_star_1.mp3');
            if (this.isFxEnabled) {
              fx_1.play();
            }
            if (this.numStars>=2)
            anime({
              targets: this,
              alpha2: 255,
              scale2: 1,
              duration: 200,
              delay:400,
              easing: 'easeInOutQuad',          
              complete: (anim) => {
                const fx_1 = new Audio('/assets/fx_star_2.mp3');
                if (this.isFxEnabled) {
                  fx_1.play();
                }
                if (this.numStars>=3)
                anime({
                  targets: this,
                  alpha3: 255,
                  scale3: 1,
                  duration: 200,
                  delay:400,
                  easing: 'easeInOutQuad',  
                  complete: (anim) => {
                    const fx_1 = new Audio('/assets/fx_star_3.mp3');
                    if (this.isFxEnabled) {
                      fx_1.play();
                    }
                  }                          
                });
              }   
            });
          }   
      });
    }

    displayStars(p5){
      let posx = p5.width/2 - 50;
      
      p5.push();  
        p5.imageMode(p5.CENTER);
        p5.image(this.staroff, posx-30, this.y+70, this.staroff.width*this.scale1, this.staroff.height*this.scale1);      
        p5.image(this.staroff, posx+50, this.y+100, this.staroff.width*this.scale2, this.staroff.height*this.scale2);            
        p5.image(this.staroff, posx+130, this.y+70, this.staroff.width*this.scale3, this.staroff.height*this.scale3);            
      p5.pop();

      p5.push(); 
        p5.imageMode(p5.CENTER); 
        if (this.numStars>=1){
          p5.tint(255, this.alpha1);
          p5.image(this.staron, posx-30, this.y+70, this.staron.width*this.scale1, this.staron.height*this.scale1);            
          
        }
        if (this.numStars>=2) 
        {
          p5.tint(255, this.alpha2);
          p5.image(this.staron, posx+50, this.y+100, this.staron.width*this.scale2, this.staron.height*this.scale2);            
          
        }
        if (this.numStars>=3) 
        {
          p5.tint(255, this.alpha3);
          p5.image(this.staron, posx+130, this.y+70, this.staron.width*this.scale3, this.staron.height*this.scale3);            
        }
      p5.pop();
    }
  
    render(p5){    
      if (!this.show || !this.image_fw) return;
      p5.noStroke();
      p5.fill(p5.color(47, 56, 27, 204));
      p5.rect(0,0, p5.width, p5.height);
      p5.push(); 
        p5.translate(-this.w/2, -this.h/2);
        p5.image(this.image_fw, this.x, this.y);     
      p5.pop();
      this.displayTexts(p5);
      this.displayTextLevel(p5);
      this.displayStars(p5)
    }    
    
}