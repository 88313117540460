import React,{useEffect, useState} from 'react';
import _ from 'lodash';
import anime from 'animejs';
import SettingsService from "../../../../services/settings";

export default function ImageMap ({setPosition, _scale, launchVideo}) {

  let [isFxEnabled, setIsFxEnabled] = useState(SettingsService.isFxEnabled());
  let [medalsAmount, setMedalsAmount] = useState(0);
  let [data, setData] = useState({});
  let [games, setGames] = useState([]);
  let [scale, setScale] = useState(1);

  let launchingLevel = false;

  const mapaImages = {
    mapa : "assets/general/mapa.jpg",
    lock : "assets/general/lock.png",
    unlock : "assets/general/unlock.png",
    game1medal : "assets/general/medalla_game1.png",
    game2medal : "assets/general/medalla_game2.png",
    game3medal : "assets/general/medalla_game3.png",
    game4medal : "assets/general/medalla_game4.png",
    game1 : "assets/general/game1.png",
    game2 : "assets/general/game2.png",
    game3 : "assets/general/game3.png",
    game4 : "assets/general/game4.png",
    game1_off : "assets/general/game1_off.png",
    game2_off : "assets/general/game2_off.png",
    game3_off : "assets/general/game3_off.png",
    game4_off : "assets/general/game4_off.png",
    game1_on : "assets/general/game1_on.png",
    game2_on : "assets/general/game2_on.png",
    game3_on : "assets/general/game3_on.png",
    game4_on : "assets/general/game4_on.png",
    game1level1 : "assets/general/game1_nivel1.png",
    game1level2 : "assets/general/game1_nivel2.png",
    game1level3 : "assets/general/game1_nivel3.png",
    game1level4 : "assets/general/game1_nivel4.png",
    game2level1 : "assets/general/game2_nivel1.png",
    game2level2 : "assets/general/game2_nivel2.png",
    game2level3 : "assets/general/game2_nivel3.png",
    game2level4 : "assets/general/game2_nivel4.png",
    game3level1 : "assets/general/game3_nivel1.png",
    game3level2 : "assets/general/game3_nivel2.png",
    game3level3 : "assets/general/game3_nivel3.png",
    game3level4 : "assets/general/game3_nivel4.png",
    game4level1 : "assets/general/game4_nivel1.png",
    game1level1_off : "assets/general/game1_nivel1_off.png",
    game1level2_off : "assets/general/game1_nivel2_off.png",
    game1level3_off : "assets/general/game1_nivel3_off.png",
    game1level4_off : "assets/general/game1_nivel4_off.png",
    game2level1_off : "assets/general/game2_nivel1_off.png",
    game2level2_off : "assets/general/game2_nivel2_off.png",
    game2level3_off : "assets/general/game2_nivel3_off.png",
    game2level4_off : "assets/general/game2_nivel4_off.png",
    game3level1_off : "assets/general/game3_nivel1_off.png",
    game3level2_off : "assets/general/game3_nivel2_off.png",
    game3level3_off : "assets/general/game3_nivel3_off.png",
    game3level4_off : "assets/general/game3_nivel4_off.png",
    game4level1_off : "assets/general/game4_nivel1_off.png",
    game_staroff : "assets/general/star_off.png",
    game_staron : "assets/general/star_on.png",
    game1arboles1 : "assets/general/game1_arboles_nivel_1.png",
    game1arboles2 : "assets/general/game1_arboles_nivel_2.png",
    game1arboles3 : "assets/general/game1_arboles_nivel_3.png",
    game1arboles4 : "assets/general/game1_arboles_nivel_4.png",
    game2arboles1 : "assets/general/game2_arboles_nivel_1.png",
    game2arboles2 : "assets/general/game2_arboles_nivel_2.png",
    game2arboles3 : "assets/general/game2_arboles_nivel_3.png",
    game2arboles4 : "assets/general/game2_arboles_nivel_4.png",
    game3arboles1 : "assets/general/game3_arboles_nivel_1.png",
    game3arboles2 : "assets/general/game3_arboles_nivel_2.png",
    game3arboles3 : "assets/general/game3_arboles_nivel_3.png",
    game3arboles4 : "assets/general/game3_arboles_nivel_4.png",
  };

  useEffect(() => {
    preload();
  },[]);

  const preload = () => {
    setScale(_scale);
    for (const [key, value] of Object.entries(mapaImages)) {
      if (!document.head.querySelector("#"+key)){
        var link = document.createElement("link");
        link.rel = "preload";
        link.as = "image";
        link.href = value;
        link.id = key;
        document.head.appendChild(link);
      }
    }

    updateData();
  }

  const updateData = () => {

    let game1 = SettingsService.get("game1");
    let game2 = SettingsService.get("game2");
    let game3 = SettingsService.get("game3");
    let game4 = SettingsService.get("game4");

    let games = [
      {status: game1.status, stars: game1.stars, isLocked:true, level: game1.level, game:{x:648, y:435}, lock:{x:996,y:565, w:150, h:180}, levels:[{x:735, y:535, w:100, h:140, active:false}, {x:873, y:618, w:100, h:140, active:false}, {x:1120, y:565, w:100, h:140, active:false}, {x:1069, y:741, w:100, h:140, active:false}]},
      {status: game2.status, stars: game2.stars, isLocked:true, level: game2.level, game:{x:1143, y:661}, lock:{x:1570,y:800, w:150, h:150, w:150, h:180}, levels:[{x:1297, y:960, w:100, h:140, active:false}, {x:1409, y:900, w:100, h:140, active:false}, {x:1720, y:734, w:100, h:140, active:false}, {x:1812, y:670, w:100, h:140, active:false}]},
      {status: game3.status, stars: game3.stars, isLocked:true, level: game3.level, game:{x:256, y:21}, lock:{x:900,y:110 , w:150, h:150}, levels:[{x:785, y:140, w:100, h:140, active:false}, {x:980, y:40, w:100, h:140, active:false}, {x:1142, y:154, w:100, h:140, active:false}, {x:1322, y:290, w:100, h:140, active:false}]},
      {status: game4.status, stars: game4.stars, isLocked:true, level: game4.level, game:{x:147, y:583}, lock:{x:350,y:885, w:150, h:150, w:150, h:180}, levels:[{x:623, y:1156, w:100, h:140, active:false}]}
    ];
    updateStatus(games);

  }

  const updateStatus = (games) => {
    games.map((el, index) =>{
      games[index].isLocked = (el.level>0)? false : true; 
      el.levels.map((level, index2) =>{ 
        let activo = (el.level == (index2+1))? true : false;
        if (el.stars[index2]>0) activo=true;
        if (el.stars[index2-1]>0) activo=true;
        games[index].levels[index2].active = activo;
      });
    });
    setGames(games);
  }

  const DisplayGames = (el, index)=>{
    return (el.isLocked ? 
      <img  className="board" style={{
        position: "absolute",
        zIndex:2,
        left:el.game.x + "px", 
        top:el.game.y + "px", 
      }} src={mapaImages[`game${index+1}_off`]}/>
    :
    !el.stars.slice(-1)[0]?
      <img className="board" style={{
        position: "absolute",
        zIndex:2,
        left:el.game.x + "px", 
        top:el.game.y + "px", 
      }}  src={ mapaImages[`game${index+1}`]}/>
      :
      <img className="board" style={{
        position: "absolute",
        zIndex:2,
        left:el.game.x + "px", 
        top:el.game.y + "px", 
      }}  src={mapaImages[`game${index+1}_on`]}/>)
  }

  const DisplayTrees = (el, index, treeDensity)=>{
    return(<img  className="tree" style={{
      position: "absolute",
      zIndex:3,
      left:el.game.x + "px",
      top:el.game.y + "px", 
    }}  src={mapaImages[`game${index+1}arboles${treeDensity}`]}/>)
  }

  const DisplayLocks = (el, index)=>{

    if (!el.isLocked){
      if (el.stars.slice(-1)[0]) {
        let medals = medalsAmount;
        medals ++;
        setMedalsAmount = medals;
      }
    }

    return(
      el.isLocked ? <img className="lock" style={{
        position: "absolute",
        zIndex:9,
        left: el.lock.x + "px",
        top: el.lock.y + "px", 
        width: el.lock.w + "px",
        
      }} onTouchEnd={()=>{if (el.status!=0) ClickLock(index)}} onMouseUp={()=>{if (el.status!=0)ClickLock(index)}} src={el.status==0 ? mapaImages.lock : mapaImages.unlock}/>
      :
      el.stars.slice(-1)[0] ? <img className="medal" style={{
        position: "absolute",
        zIndex:9,
        left: el.lock.x + "px",
        top: el.lock.y + "px", 
        width: el.lock.w + "px",        
      }}  src={mapaImages[`game${index+1}medal`]}/>:<></>
    )
  }

  const LaunchLevel = (e, index, index2) => {

    if (launchingLevel) { return; }
    
    launchingLevel = true;
    setPosition();
    
    if (isFxEnabled) {
      const fx_1 = new Audio('/assets/fx_click.mp3');    
      fx_1.play();
    }   
    
    anime({
      targets: document.getElementById("level"+index+"_"+index2),
      scale: 1.2,
      duration: 100,
      easing: 'easeInOutQuad',  
      direction: 'alternate', 
      complete: (anim) => {         
          window.location.href = "/#/game"+(index+1)+"/"+(index2+1);     
          launchingLevel = false; 
      }
    });
    
    

  }

  const ClickLock = (index) => {
    
    if (index>=1){
      if (games[index-1].stars[0]<=0) return;
    }

    if (isFxEnabled) {
      const fx_1 = new Audio('/assets/fx_unlock.mp3');    
      fx_1.play();
    }    
    
    //launch video
    if (launchVideo && games[index].isLocked) {
      launchVideo(index + 1);
    }

    var _games = [...games];
    _games[index].status = 2;
    _games[index].level = 1;
    _games[index].isLocked = false;    
    setGames(_games);
     
    if (isFxEnabled) {
      const fx_1 = new Audio('/assets/fx_reveal.mp3');    
      fx_1.play();
    }       

    setPosition(); 
    updateStatus(_games)

  }

  const DisplayStars = (el, index)=>{    
    return(  
      el.levels.map((level, index2) =>        
          level.active ?
          <div className="level_stars" style={{
            position: "absolute",
            zIndex:4,
            left:level.x + "px",
            top:level.y + "px", 
          }} id={"level"+index+"_"+index2} key={"level"+index+"_"+index2}>
            <img className="level" style={{
              width:level.w + "px",
              height:level.h + "px"
            }} onTouchEnd={(e)=>{LaunchLevel(e, index, index2)}} onMouseUp={(e)=>{LaunchLevel(e,index, index2)}} src={mapaImages["game"+(index+1)+"level"+(index2+1)]}/>
            <img className="star" style={{
              position: "absolute",
              zIndex:4,
              width:"40px",
              height:"40px",
              left: "0px",
              top: "0px", 
            }}  src={el.stars[index2]>=1 ? mapaImages["game_staron"] : mapaImages["game_staroff"]}/>
            <img className="star" style={{
              position: "absolute",
              zIndex:4,
              width:"40px",
              height:"40px",
              left:"30px",
              top: "-10px", 
            }}  src={el.stars[index2]>=2 ? mapaImages["game_staron"] : mapaImages["game_staroff"]}/>
            <img className="star" style={{
              position: "absolute",
              zIndex:4,
              width:"40px",
              height:"40px",
              left:  "60px",
              top: "0px", 
            }}  src={el.stars[index2]>=3 ? mapaImages["game_staron"] : mapaImages["game_staroff"]}/>
          </div>
          :        
            <img key={"level"+index+"_"+index2} className="level" style={{
              position: "absolute",
              zIndex:4,
              left: level.x + "px",
              top:level.y + "px", 
              width:level.w + "px",
              height:level.h + "px"
            }}  src={mapaImages["game"+(index+1)+"level"+(index2+1)+"_off"]}/>
          )             
      )
    
  }

  const DisplayActors = (el, index) => {
    if (el.status>1){
      if (index==0) return <div className="personaje-loop" style={{
        position: "absolute",
        top:"40%",
        left:"50%",
        zIndex:4,
        backgroundImage: "url(assets/general/ambi-loop-min.png)"
      }}></div>
      if (index==2) return <div className="personaje-loop" style={{
        position: "absolute",
        top:"116px",
        left:"911px",
        zIndex:4,
        backgroundImage: "url(assets/general/land-loop-min.png)"
      }}></div>
      if (index==1) return <div className="personaje-loop" style={{
        position: "absolute",
        top:"880px",
        left:"1593px",
        zIndex:4,
        backgroundImage: "url(assets/general/ledi-loop-min.png)"
      }}></div>
    }else return <></>      
  }
  
  return (
    
    <div id="menu">
        {
        games.map((el, index) =>{ 
          let treeDensity = 0;

          if ([0, 1, 2].includes(index)) {
            treeDensity = 0;
            if (el.stars[3]) {
              treeDensity = 4;
            } else if (el.stars[2]) {
              treeDensity = 3;
            } else if (el.stars[1]) {
              treeDensity = 2;
            } else if (el.stars[0]) {
              treeDensity = 1;
            }
          }
          return(
            <div key={"image_"+index}>      
                {index<=3 ? DisplayActors(el, index):<></>}                                           
                {DisplayLocks(el, index)}
                {DisplayStars(el, index)}
                {treeDensity ? DisplayTrees(el, index, treeDensity):<></>}
                {DisplayGames(el, index)}
            </div>
          )
        })        
        }

        <img style={{
          position: "absolute",
          left: "0px", 
          top:  "0px", 
          zIndex:1,
        }} src="assets/general/mapa.jpg"/>
          
    </div>
  );

    
    
}