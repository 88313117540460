import Matter from "matter-js";

export default class Bird {
    constructor(x, y, r, image, container, _world) {
      const options = {
        label:"bird",
        container: container,
        restitution: 0.5
      };
      this.body = Matter.Bodies.rectangle(x, y, image.width/4, image.height/4, options);
      Matter.Body.setMass(this.body, this.body.mass * 4);
      Matter.World.add(_world, this.body);
      this.r = r;
      this.image = image;
    }

    getContainer() { return this.container; }
  
    show(p5) {
      const pos = this.body.position;
      const angle = this.body.angle;
      p5.push();
      p5.translate(pos.x, pos.y);
      p5.rotate(angle);
      p5.imageMode(p5.CENTER);
      p5.rectMode(p5.CENTER);
      p5.image(this.image, 0, 0, this.image.width/4, this.image.height/4);
      /*p5.noFill();
      p5.rect(0,0,this.image.width/4, this.image.height/4)*/
      p5.pop();
    }
  }