import React, { useEffect } from 'react';
import _ from 'lodash';
import Hammer from "hammerjs";

import "./TutorialWindow.css";

let tutorialId;
let currentSlide = 0;

const tutorials = {
    "1": { slides: [
        '/ayudas/ayuda-juego01-01.png',
        '/ayudas/ayuda-juego01-02.png',
        '/ayudas/ayuda-juego01-03.png',
        '/ayudas/ayuda-juego01-04.png',
        '/ayudas/ayuda-juego01-05.png'        
    ]},
    "2": { slides: [
        '/ayudas/ayuda-juego02-01.png',
        '/ayudas/ayuda-juego02-02.png',
        '/ayudas/ayuda-juego02-03.png',
        '/ayudas/ayuda-juego02-04.png'
    ]},
    "3": { slides: [
        '/ayudas/ayuda-juego03-01.png',
        '/ayudas/ayuda-juego03-02.png',
    ]},
    "4": { slides: [
        '/ayudas/ayuda-juego04-01.png'
    ]}
}

/*
const newCarousel = () => {
	
    var activeID = 0,
        itemW = 940,
        carousel_count = document.querySelector('.carousel_item').length,
        $carouselItems = document.querySelector('.carousel_items'),
        $carouselItem = document.querySelector('.carousel_item'),
        $arrowPrev = document.querySelector('.item_prev'),
        $arrowNext = document.querySelector('.item_next'),
        $itemArrow = document.querySelector('.item_arrow'),
        $navDot,
        $navDots = document.querySelector('.nav_dots'),
        swipeDir,
        slideSpeed = .45,
        slideMeth = Power2.EaseInOut;
    

    function init() {
    
    $carouselItems.css({'width': (itemW * carousel_count) + 'px'});
    $navDots.css({'width': (25 * carousel_count) + 'px'});
    $itemArrow.css({'opacity': .8});
    
    setupDraggable();
    setupDots();
    navigateSlide();
    }
    init();
    
    //
    function setupDraggable() { 
    
    Draggable.create($carouselItems, {
            type:'x',
            edgeResistance: 0.90,
            dragResistance: 0.0,
            bounds:'.carousel_container',
            onDrag:updateDirections,
            onThrowUpdate:updateDirections,
            throwProps:true,
            onDragStart:function(evt) {},
            onDragEnd :function() {

            if(swipeDir == 'left') {activeID++}
            else if(swipeDir == 'right') {activeID--};
            
            navigateSlide();
            }
    });    
    };
                
    // set up dots
    function setupDots() {    
    for(var i = 0; i < carousel_count; i++) {
    $navDots.append('<div class="nav_dot" id="dot_' + i + '"></div>');
    }    
    $navDot = $('.nav_dot');
    }  

    // navigate slide
    function navigateSlide() {
        
        if(activeID >= carousel_count-1) activeID = carousel_count-1;
        if(activeID <= 0) activeID = 0;		
                        
        var xTarget = ((activeID * itemW) * -1);
        
        TweenMax.to($carouselItems, slideSpeed, {x: xTarget, ease: slideMeth, onComplete: slideDone});
    }
    
    function slideDone() {
        
        $navDot.css({backgroundColor: '#fff'});
    
    //
        TweenMax.to($navDot, .35, {scale: 1, color: 0xFFFFFF});
        TweenMax.to($('#dot_' + activeID), .35, {scale: 1.5, backgroundColor: 'transparent',color: 0xCC0000});
    
    //
    if(activeID == 0) {$arrowPrev.fadeOut()} 
    else {$arrowPrev.fadeIn()}
    
    if(activeID + 1 == carousel_count) {$arrowNext.fadeOut()}
    else {$arrowNext.fadeIn()}
    }
    
    //
    function updateDirections() {
        swipeDir = this.getDirection("start");
    }
    
    //$itemArrow.click(function() {
    $itemArrow.on('click', function() {
    
    if(Modernizr.touch) return;
    
    if($(this).hasClass('item_next')) {activeID++}
    else {activeID--};
    
    navigateSlide();
    });

    $itemArrow.on('touchstart', function() {
    if($(this).hasClass('item_next')) {activeID++}
    else {activeID--};
    
    navigateSlide();
    });

    $navDot.hover(		
        function() {			
            TweenMax.to($(this), .35, {scale: 1.5});
        }, function() {
            if($(this).attr('id').split('_')[1] == activeID) return;
            TweenMax.to($(this), .35, {scale: 1.0});
        }  
    );
    
    $navDot.click(function() {		
    var dotID = $(this).attr('id').split('_')[1];
        activeID = dotID;
            
    navigateSlide();		
    });

    //
    $carouselItem.mousedown(function() {		
        activeID = $(this).attr('id').split('_')[1];
    
    $(this).removeClass('grab');
    $(this).addClass('grabbing');
    
    });

    //   
    $carouselItem.mouseenter(function() {        
    $(this).removeClass('grabbing');
    $(this).addClass('grab');
    });

    $carouselItem.mouseup(function() {        
    $(this).removeClass('grabbing');
    $(this).addClass('grab');
    });  

}

*/

const swiped = event => {

    if (event.direction == 4) {
        console.log("you swiped right");

        if (currentSlide > 0) {
            currentSlide--;
        } else {
            currentSlide = tutorials[tutorialId].slides.length - 1;
        }
        document.getElementById("tutorialSlide").style.backgroundImage = `url('${tutorials[tutorialId].slides[currentSlide]}')`;

    } else if (event.direction == 2) {
        console.log("you swiped left");

        if (_.get(tutorials, `[${tutorialId}].slides[${currentSlide + 1}]`)) {
            currentSlide++;
        } else {
            currentSlide = 0;
        }
        document.getElementById("tutorialSlide").style.backgroundImage = `url('${tutorials[tutorialId].slides[currentSlide]}')`;

    }

    _.each(tutorials[tutorialId].slides, (slide, index) => {
        document.getElementById(`dot_${index}`).classList.remove("active");   
    });
    document.getElementById(`dot_${currentSlide}`).classList.add("active");

}

export const showTutorial = (id, scale) => {

    tutorialId = id;
    currentSlide = 0;
    
    var hammer = new Hammer(document.getElementById("tutorialSlide"), { preventDefault: true, stopPropagation: true});
    hammer.get('swipe').set({
      direction: Hammer.DIRECTION_ALL
    });
    hammer.on("swipe", swiped);

    document.getElementById("tutorialSlide").addEventListener('touchstart', event => { event.stopPropagation(); });
    document.getElementById("tutorialSlide").addEventListener('mousedown', event => { event.stopPropagation(); });

    _.each(tutorials[tutorialId].slides, (slide, index) => {
        const dot = document.createElement('div');
        dot.classList.add("nav_dot");
        dot.setAttribute("id", `dot_${index}`);
        document.getElementById("nav_dots").append(dot);
    });
    document.getElementById(`dot_${currentSlide}`).classList.add("active");

    document.getElementById("tutorialSlide").style.backgroundImage = `url('${tutorials[tutorialId].slides[currentSlide]}')`;
    document.getElementById('tutorialContainer').style.transform  = `scale(${scale})`;
    document.getElementById("tutorialWindow").style.display = 'block';

}

export default function TutorialWindow(props) {

    useEffect( () => {
        const height = 537;
        document.getElementById('tutorialContainer').style.marginTop = (window.innerHeight - height) / 2 + 'px';
     }, []);

    const closeWindow = () => {    
        document.getElementById('tutorialWindow').style.display = 'none';
        window.location.href = "/#/game"+tutorialId+"/1";
    }

    return (
        <div id="tutorialWindow" style={{"display": "none"}} onClick={ (e) => { e.stopPropagation(); } }>
            <div id="tutorialContainer" style={{backgroundImage: 'url(assets/general/videowindow.png)'}}>
            <div id="tutorialSlide">
                <div id="nav_dots" />
            </div>
            <button id="skipButton" onClick={closeWindow} style={{backgroundImage: 'url(assets/general/btn_skip.png)'}}></button>
            </div>
        </div>
    );

}