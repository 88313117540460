export default class TopBar {
    constructor(score, x, y, w, h, header, maxScore) {
      this.score = score
      this.x = x;
      this.y = y;
      this.w = w;
      this.h = h;
      this.header = header;
      this.maxScore = maxScore;
    }   
    
    setScore(score) {
      this.score = score;
      if (this.score < 0) this.score = 0;
      if (this.score > this.maxScore) this.score = this.maxScore;
    }

    getScore() {
      return this.score;
    }
  
    show(p5) {
      let percent = (this.w) * this.score / this.maxScore;
      p5.image(this.header,this.x,this.y);
      p5.push();              
        if (this.score>0) p5.fill("white");
        else p5.fill("#ccdc9d");
        p5.noStroke();
        p5.circle(this.x+60, 38, 15);
        p5.fill("#ccdc9d");
        p5.rect(this.x + 60, 35, this.w, 6, 20); 
        p5.fill("white");
        p5.rect(this.x + 60, 35, percent, 6, 20); 
      p5.pop();
    }
  }