export default class Timer {

    constructor( p5, _duration, start = false ) {
        this.startTime = p5.millis();
        this.duration = _duration;
        this.paused = true;
        this.pauseStartTime = 0;
        this.totalPauseTime = 0;
        this.p5 = p5;
    }

    start() { 
        
        if( this.expired() ) {            
            this.startTime = this.p5.millis();
        }

        else if( this.paused ) { 
            this.totalPauseTime += this.p5.millis() - this.pauseStartTime;
            this.paused = false; 
        }
    }

    expired() {
        return (this.startTime + this.duration + this.getPauseTime() ) < this.p5.millis();
    }

    reset(){
        this.startTime = this.p5.millis();
         this.totalPauseTime = 0;

        if( this.paused ) {
            this.pauseStartTime = this.startTime;
        }
        else {
            this.pauseStartTime = 0;
        }
    }

    pause(){
        if( this.paused === false ) {
            this.pauseStartTime = this.p5.millis();
            this.paused = true; 
        }
    }
    
    addTime(x){
        this.duration += x; 
    }

    setTimer(_duration) {
        this.duration = _duration;
    }
    
    endTimer(){
        this.duration = 0; 
    }

    isPaused() {
        return this.paused;
    }

    getRemainingTime() {
        if( this.expired() ) {
            return 0;
        }

        let rt = this.startTime + this.duration + this.getPauseTime() - this.p5.millis();
        if( rt < 0 )
            rt = 0;
        return rt;
    }  

    getPercentageRemaining() {
        if( this.duration === 0 )  { 
            return 0.0;
        }
        if( this.expired() ) { 
            return 0.0;
        }

        return this.getRemainingTime()/this.duration;
    }

    getPercentageElapsed() {
        return 1.0 - this.getPercentageRemaining();
    }	

    getPauseTime() {
       let pauseTime = this.totalPauseTime;
       if( this.paused ) {
            pauseTime += (this.p5.millis() - this.pauseStartTime);
       }
       return pauseTime;
    } 	
}