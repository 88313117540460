import _ from 'lodash';

const genericParamsDefaults = {
    name: "",
    game1: {status:1, level:0, stars:[0,0,0,0]}, 
    game2: {status:0, level:0, stars:[0,0,0,0]}, 
    game3: {status:0, level:0, stars:[0,0,0,0]}, 
    game4: {status:0, level:0, stars:[0]},
    trees: 0, 
    stars: 0,
    map: {x:0, y:0, offsetx:0, offsety:0},
    isIntroVideoShown: false
    /*
    intro: false, 
    endvideo: false, 

    */
};

const data = JSON.parse(localStorage.getItem("settings")) || {};

const saveData = () => {
    localStorage.setItem("settings", JSON.stringify(data));
}

export const isMusicEnabled = () => {
    return _.get(data, 'music', true);
}

export const toggleMusic = () => {
    _.set(data, 'music', !isMusicEnabled());
    saveData();
}

export const isFxEnabled = () => {
    return _.get(data, 'fx', true);
}

export const toggleFx = () => {
    _.set(data, 'fx', !isFxEnabled());
    saveData();
}

export const getDifficulty = () => {
    return _.get(data, 'difficulty', 1);
}

export const changeDifficulty = (difficulty) => {
    _.set(data, 'difficulty', parseInt(difficulty) || 0);
    saveData();
}

export const changeFinalVideoShown = (status) => {
    _.set(data, 'finalVideoShown', !!status);
    saveData();
}

export const isTutorialAlreadyPlayed = tutorialId => {
    return _.get(data, `tutorial${tutorialId}AlreadyPlayed`, false);
}

export const changeTutorialPlayed = (tutorialId, status) => {
    _.set(data, `tutorial${tutorialId}AlreadyPlayed`, !!status);
    saveData();
}

export const get = key => {
    console.log('get', key, _.get(data, key, _.get(genericParamsDefaults, key)));
    return _.get(data, key, _.get(genericParamsDefaults, key));
}

export const set = (key, value) => {
    _.set(data, key, value);
    console.log('set', key, value);
    saveData();
}

export default {
    isMusicEnabled,
    toggleMusic,
    isFxEnabled,
    toggleFx,
    getDifficulty,
    changeDifficulty,
    changeFinalVideoShown,
    isTutorialAlreadyPlayed,
    changeTutorialPlayed,
    get,
    set,
};