import anime from 'animejs';

export default class FlyingBulb {
    constructor(id, btype , xini, yini, image, xfin, yfin, w, h) {
        this.id = id;
        this.btype = btype;
        this.image = image;
        this.x = xini;
        this.y = yini;
        this.xfin = xfin;
        this.yfin = yfin;
        this.w = w;
        this.h = h;
        this.alpha = 2000;
        this.die = false;
        this.jiggle();
    }

    isDie() {
        return this.die;
    }

    jiggle(){
        anime({
            targets: this,
            x: this.xfin,
            y: this.yfin,
            w: this.w/2,
            h: this.w/2,
            alpha: 0,
            duration: 500,
            easing: 'easeInOutQuad',
            delay: Math.random()*500,
            complete: (anim) => {
                if (anim.completed) this.die = true;
            }            
        });
    }

    displayImage(p5){
        p5.push();
            p5.translate(this.x+(this.w/2), this.y+(this.h/2));
            p5.tint(255, this.alpha);
            p5.image(this.image, -(this.w/2), -(this.h/2), this.w, this.h);              
        p5.pop();
    }
  
    render(p5) {         
        if (!this.die) this.displayImage(p5);                
    }
  
}