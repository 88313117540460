import React, { useEffect, useState, useParams, useCallback, useRef} from 'react';
import _ from 'lodash';
import "./Dificultad.css";
import SettingsService from "../../services/settings";
import anime from 'animejs';
export default function Dificultad(props) {  

  let scale = 1;

  useEffect(() =>{
    document.getElementById("root").style.backgroundImage = "url(/assets/datos/bg.jpg)";
    if (document.getElementById("p5_loading")) document.getElementById("p5_loading").style.display = "none";
    scaleCanvas();
    if (document.getElementById("p5_loading_intro")) {
      document.getElementById("p5_loading_intro").style.display = "block";
      document.getElementById("p5_loading_intro").style.opacity = 1;
      anime({
        targets: document.getElementById("p5_loading_intro"),
        opacity:0,
        duration: 1000,
        delay:1000,
        easing: 'easeInOutQuad',   
        complete: (anim) => {
          document.getElementById("p5_loading_intro").style.display = "none";
        }
      });
    }
  },[])

  useEffect(() => {
    function handleResize() {
      scaleCanvas();
    }

    window.addEventListener('resize', handleResize)
  })

  const scaleCanvas = () => {
    let gameSize = {w:1920,h:640};
    scale = (window.innerHeight+20) / gameSize.h;
    document.getElementById("defaultCanvas0").style.transform = "translate(-50%, -50%) scale("+scale+")";        
  }

  const button1HandleClick = (event) => {
    event.preventDefault();
    SettingsService.changeDifficulty(0);    
    window.location.href = "/#/menu";        
  }

  const button2HandleClick = (event) => {
    event.preventDefault();
    SettingsService.changeDifficulty(1);
    window.location.href = "/#/menu";    
  }

  const button3HandleClick = (event) => {
    event.preventDefault();
    SettingsService.changeDifficulty(2);
    window.location.href = "/#/menu";    
  }
  
  return (
    <div className='Dificultad' id="defaultCanvas0" >
        <div className='content'>
          <img className='bg' src="/assets/dificultad/panel.png"></img>
          <div className='buttons'>
            <button id="button_1" style={{ backgroundImage: "url(/assets/dificultad/nivel_0.png)"}}  onClick={button1HandleClick}></button>
            <button id="button_2" style={{ backgroundImage: "url(/assets/dificultad/nivel_1.png)"}}  onClick={button2HandleClick}></button>
            <button id="button_3" style={{ backgroundImage: "url(/assets/dificultad/nivel_2.png)"}}  onClick={button3HandleClick}></button>
          </div>
        </div>
    </div>
  )
  
}