import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

function onDeviceReady() {

  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
    /*<React.StrictMode>*/
      <App />
    /*</React.StrictMode>*/
  );
  
  reportWebVitals();
  
}

if (!window.cordova) {
  onDeviceReady();
} else {
  document.addEventListener('deviceready', onDeviceReady, false);
}


