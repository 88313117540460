import React from 'react';
import {useParams} from "react-router-dom";
import _ from 'lodash';
import "./Game3.css";
import anime from 'animejs';
import Sketch from "react-p5";
import CountDown from "./components/CountDown";
import Tile from "./components/game3/tile";
import TileCircular from "./components/game3/tileCircular";
import Hammer from "hammerjs";
import Bubble from "./components/game3/bubble";
import feedbackWindow from './components/feedbackWindow';

import SettingsService from "../../services/settings";

export default function Game3(props) {  
  
  let { paramLevel } = useParams();
  let level = {
    x: 0,       
    y: 0,
    marginx: 5,
    marginy: -16,
    level: parseInt(paramLevel)-1,       
    columns: 5,   
    rows: 4,      
    tilewidth: 119,
    tileheight: 119,
    tiles: [],      
    selectedtile: { selected: undefined, column: 0, row: 0 },
    dificult1:{time:[300000, 200000, 100000, 100000], points:[10,15,20,25]}
  };

  /*let levels = [
    { start: [0,-1,-1,7,-1,4,-1,9,-1,-1,4,9,6,-1,9,3,-1,-1,8,-1,3,9,-1,9,-1,5,1,2,7,-1,-1,9,-1,10,9], points:8, end:[-1,-1,-1,-1,-1,4,-1,-1,-1,-1,4,-1,-1,-1,-1,3,-1,-1,-1,-1,3,-1,-1,-1,-1,5,1,2,7,-1,-1,-1,-1,-1,-1]},
    { start: [0,6,-1,-1,8,5,2,7,9,-1,4,9,3,9,-1,-1,-1,5,7,9,1,-1,9,4,-1,-1,8,-1,3,-1,9,-1,-1,10,9], points:8, end:[-1,-1,-1,-1,-1,5,2,7,-1,-1,-1,-1,3,-1,-1,-1,-1,5,7,-1,-1,-1,-1,4,-1,-1,-1,-1,3,-1,-1,-1,-1,-1,-1]},
    { start: [0,6,-1,-1,8,5,2,7,9,-1,4,9,3,9,-1,-1,-1,5,7,9,1,-1,9,4,-1,-1,8,-1,3,-1,9,-1,-1,10,9], points:8, end:[-1,-1,-1,-1,-1,5,2,7,-1,-1,-1,-1,3,-1,-1,-1,-1,5,7,-1,-1,-1,-1,4,-1,-1,-1,-1,3,-1,-1,-1,-1,-1,-1]},
    { start: [0,6,-1,-1,8,5,2,7,9,-1,4,9,3,9,-1,-1,-1,5,7,9,1,-1,9,4,-1,-1,8,-1,3,-1,9,-1,-1,10,9], points:8, end:[-1,-1,-1,-1,-1,5,2,7,-1,-1,-1,-1,3,-1,-1,-1,-1,5,7,-1,-1,-1,-1,4,-1,-1,-1,-1,3,-1,-1,-1,-1,-1,-1]}
  ];*/

  let levels = [
    { start: [0,-1,1,-1,5,2,2,7,9,-1,9,3,8,-1,9,4,-1,-1,-1,10], points:6, end:[-1,-1,-1,-1,5,2,2,7,-1,-1,-1,3,-1,-1,-1,4,-1,-1,-1,-1]},
    { start: [0,-1,6,7,3,9,3,10,5,2,8,4,-1,-1,9,-1,1,9,-1,-1], points:7, end:[-1,-1,6,7,3,-1,3,-1,5,2,8,-1,-1,-1,-1,-1,-1,-1,-1,-1]},
    { start: [0,6,2,7,5,8,-1,3,-1,-1,9,3,-1,9,6,8,-1,-1,10,-1], points:9, end:[0,6,2,7,5,8,-1,3,-1,-1,-1,3,-1,-1,6,8,-1,-1,-1,-1]},
    { start: [0,6,7,-1,3,10,3,-1,3,9,4,-1,5,2,8,-1,8,-1,-1,1], points:9, end:[0,6,7,-1,3,-1,3,-1,3,-1,4,-1,5,2,8,-1,-1,-1,-1,-1]}
  ];

  let fW;
  let fixedimages = [];
  let tileimages = [];
  let empty;
  let paused = true;
  let star, staron, staroff;
  let countDownTimer;
  let gameover = false;
  let drag = false;
  let currentmove = { targetCol: 0, targetRow: 0, sourceCol: 0, sourceRow:0 };
  let gamestates = { init: 0, ready: 1, resolve: 2 };
  let gamestate = gamestates.init;
  let tileMoving = undefined;
  const scores = [];
  let pos, img_tablero, img_header, img_tile_mask, background, tuberia_1, tuberia_2, tuberia_3;
  let starsActived = false;
  let gameSize = {w:960,h:640};
  let scale=1;
  let homeButton;
  let levelwidth;
  let levelheight;

  let isMusicEnabled = SettingsService.isMusicEnabled();
  let isFxEnabled = SettingsService.isFxEnabled();
  let difficulty = SettingsService.getDifficulty();

  const preload = (p5) => {           
    
    empty = p5.loadImage("/assets/game3/empty.png");
    star = p5.loadImage("/assets/game1/star.png");

    tileimages.push(p5.loadImage("/assets/game3/pieza_inicio.png"));
    tileimages.push(p5.loadImage("/assets/game3/tile_1_anim.png"));
    tileimages.push(p5.loadImage("/assets/game3/tile_1_anim.png"));
    tileimages.push(p5.loadImage("/assets/game3/tile_3_anim.png"));
    tileimages.push(p5.loadImage("/assets/game3/tile_3_anim.png"));
    tileimages.push(p5.loadImage("/assets/game3/circleanim.png"));
    tileimages.push(p5.loadImage("/assets/game3/circleanim.png"));
    tileimages.push(p5.loadImage("/assets/game3/circleanim.png"));
    tileimages.push(p5.loadImage("/assets/game3/circleanim.png"));
    tileimages.push(p5.loadImage("/assets/game3/pieza_fijo.png"));
    tileimages.push(p5.loadImage("/assets/game3/pieza_fin.png"));

    fixedimages.push(undefined);
    fixedimages.push(p5.loadImage("/assets/game3/tile_1_fix.png"));
    fixedimages.push(p5.loadImage("/assets/game3/tile_1_fix.png"));
    fixedimages.push(p5.loadImage("/assets/game3/tile_3_fix.png"));
    fixedimages.push(p5.loadImage("/assets/game3/tile_3_fix.png"));
    fixedimages.push(p5.loadImage("/assets/game3/tile_5_fix.png"));
    fixedimages.push(p5.loadImage("/assets/game3/tile_6_fix.png"));
    fixedimages.push(p5.loadImage("/assets/game3/tile_7_fix.png"));
    fixedimages.push(p5.loadImage("/assets/game3/tile_8_fix.png"));
    fixedimages.push(undefined);
    fixedimages.push(undefined);

    staron = p5.loadImage("/assets/game1/star.png");
    staroff = p5.loadImage("/assets/game3/staroff.png");
    img_tablero = p5.loadImage("/assets/game3/tablero.png");
    img_header = p5.loadImage("/assets/game3/header.png");
    img_tile_mask = p5.loadImage("/assets/game3/tile_mask.png");
    background = p5.loadImage("/assets/game3/bg.jpg");

    /*
    tuberia_1 = p5.loadImage("/assets/game3/tuberia_1.png");
    tuberia_2 = p5.loadImage("/assets/game3/tuberia_2.png");
    tuberia_3 = p5.loadImage("/assets/game3/tuberia_3.png");
    */

  };


  const setup = (p5, canvasParentRef) => {    
    p5.createCanvas(gameSize.w, gameSize.h).parent(canvasParentRef);
    scaleCanvas(p5);    
    console.log("paramLevel", paramLevel);
    level.x = p5.width/2 - (level.tilewidth*level.columns)/2;
    level.y = 50;

    let options = {
      preventDefault: true
    };

    let hammer = new Hammer(document.body, options);
    hammer.get('swipe').set({
      direction: Hammer.DIRECTION_ALL
    });
    fW = new feedbackWindow(p5, "Game3", p5.width/2, p5.height/2, scale, changeLevelCallback);
    fW.setLevel(paramLevel);
    scores.push(new Bubble(1, 0, staroff, staron));
    scores.push(new Bubble(2, 0, staroff, staron));
    scores.push(new Bubble(3, 0, staroff, staron));

    homeButton = p5.createButton("");
    homeButton.position(120,48);
    homeButton.id("homeButton");
    homeButton.parent("Game3");
    homeButton.style("background-image","url(/assets/game1/btn_home.png)");
    homeButton.mousePressed(button1HandleClick);

    hammer.on("swipe", swiped);
    document.getElementById("root").style.backgroundImage = "url(/assets/game3/bg.jpg)";
    document.getElementById("overlay_left").style.display = "none";
    document.getElementById("overlay_right").style.display = "none";

    /*
    let music = document.getElementById("music");
    music.src = '/assets/music_loop2.mp3';
    if (isMusicEnabled) {
      music.play();
    }
    */
    
    newGame(p5); 
          
  }

  const changeLevelCallback = (level) => {
    paramLevel = level;
  }

  const findCenter = (p5) => {
    console.log("center")
    level.x = p5.width/2 - (level.columns * level.tilewidth)/2 ;
    level.y = 150;
    //level.tilewidth = level.tileheight = (p5.height-300)/level.rows;    
    
    for (let i=0; i<level.columns; i++) {
      for (let j=0; j<level.rows; j++) {          
        const x = level.marginx + level.x + (level.tilewidth*i);
        const y = level.marginy + level.y + (level.tileheight*j);
        if (level.tiles[i][j]) level.tiles[i][j].setResize(x,y,level.tilewidth,level.tileheight);
      }   
    }

    scores[0].setX(level.x + 50);
    scores[1].setX(level.x + 180);
    scores[2].setX(level.x + 310);

    if (countDownTimer) countDownTimer.setPosition({x:level.x + 515, y:50})    
  }

  const newGame = (p5) => {    
    p5.frameRate(60);
    countDownTimer = new CountDown(p5,level["dificult1"].time[paramLevel-1]); 
    gameover = false;
    let count = 0;
    let items = [];
    let tablero = [];
    let huecos = [];
    starsActived = false;
    
    for (let i=0; i<levels[level.level].start.length; i++) {
      if (levels[level.level].start[i]>=1 && levels[level.level].start[i]<9){
        items.push(levels[level.level].start[i]);
        tablero[i] = -1;
        huecos.push(i);
      }else{
        if (levels[level.level].start[i]==-1) huecos.push(i);
        tablero[i] = levels[level.level].start[i];
      }
    }

    scores.map((el, i) =>{
      el.setScore(0);      
    });

    items = _.shuffle(items);
    huecos = _.shuffle(huecos);

    huecos.map((el, i) =>{
      if (items.length>0) tablero[el] = items.pop();        
    });
    
    for (let i=0; i<level.columns; i++) {
      level.tiles[i] = [];
      for (let j=0; j<level.rows; j++) {
          if (tablero[count]===5 || tablero[count]===6 || tablero[count]===7 || tablero[count]===8) level.tiles[i][j] = new TileCircular (count, tablero[count], tileimages[tablero[count]], 0, 0, level.tilewidth, level.tileheight, star, fixedimages[tablero[count]], img_tile_mask);
          else level.tiles[i][j] = new Tile (count, tablero[count], tileimages[tablero[count]], 0, 0, level.tilewidth, level.tileheight, star, fixedimages[tablero[count]]);
          count++;
      }
    }

    findCenter(p5);  

    levelwidth = level.columns * level.tilewidth;
    levelheight = level.rows * level.tileheight;
    
    if (document.getElementById("p5_loading_intro")) {
      document.getElementById("p5_loading_intro").style.display = "block";
      document.getElementById("p5_loading_intro").style.opacity = 1;
      anime({
        targets: document.getElementById("p5_loading_intro"),
        opacity:0,
        duration: 1000,
        delay:1000,
        easing: 'easeInOutQuad',   
        complete: (anim) => {
          document.getElementById("p5_loading_intro").style.display = "none";
        }
      });
    }

    if (!SettingsService.isTutorialAlreadyPlayed(3)) {      
      document.getElementById("game3Ayuda").style.display = "block";
      SettingsService.changeTutorialPlayed(3, true);
    } else {
      document.getElementById("game3Ayuda").style.display = "none";
      startGame();
    }      

  }


  const getTileCoordinate = (column, row, columnoffset, rowoffset) => {
    let tilex = level.marginx + level.x + ((column) * level.tilewidth);
    let tiley = level.marginy + level.y + ((row) * level.tileheight);
    return { x: tilex, y: tiley};
  }

  const swap = (x1, y1, x2, y2) => {
    let typeswap1 = level.tiles[x1][y1];
    let typeswap2 = level.tiles[x2][y2];
    level.tiles[x1][y1] = typeswap2;
    level.tiles[x2][y2] = typeswap1;
  }

  const renderTiles = (p5) => {
    const isCurrentMove = currentmove.sourceCol >= 0 && currentmove.sourceCol < level.columns &&
                          currentmove.sourceRow >= 0 && currentmove.sourceRow < level.rows &&
                          level.tiles[currentmove.sourceCol][currentmove.sourceRow].getTileType() >= 1 &&
                          level.tiles[currentmove.sourceCol][currentmove.sourceRow].getTileType() < 9;
                          
    for (let i = 0; i < level.columns; i++) {
        for (let j = 0; j < level.rows; j++) {                    
            const tile = level.tiles[i][j];           
            const tileType = tile.getTileType();      
            const isMovingTile = isCurrentMove && currentmove.sourceCol === i && currentmove.sourceRow === j;

            if (isMovingTile) {
                if (tileMoving && !tileMoving.isMoving()) {
                    swap(currentmove.targetCol, currentmove.targetRow, currentmove.sourceCol, currentmove.sourceRow);
                    tileMoving = undefined;
                } else if (!tileMoving) {   
                    const pos = getTileCoordinate(currentmove.targetCol, currentmove.targetRow, 0, 0);                
                    tile.move(pos.x, pos.y);
                    tileMoving = tile;                
                }
            }
          
            tile.render(p5);                 
        }
    }   
  };

  const renderEmptyTiles = (p5) => {
    p5.push();
    p5.noStroke();
    p5.fill(240, 240, 240); // color of empty tile
    for (let i = 0; i < level.columns; i++) {
      for (let j = 0; j < level.rows; j++) {
        const x = level.marginx + level.x + level.tilewidth * i;
        const y = level.marginy + level.y + level.tileheight * j;
        p5.image(empty, x, y, level.tilewidth, level.tileheight);
      }
    }
    p5.pop();
  }

  const deselectAll = () => {
    for (let i=0; i<level.columns; i++) {
      for (let j=0; j<level.rows; j++) {          
        level.tiles[i][j].deselect();
      }   
    }
  }

  const activeStars = (p5) => {
    starsActived = true;
    let count = 0;
    const tilesWithStars = [];
    
    // Create array of tiles with stars
    level.tiles.forEach((column) => {
      column.forEach((tile) => {
        if (tile.hasStar()) {
          tilesWithStars.push(tile);
        }
      });
    });
  
    // Activate stars for each tile in array
    tilesWithStars.forEach((tile) => {
      const response = tile.activeStars(scores[count], scores[count].getX(), scores[count].getY());
      console.log("activatestars", response);
      if (response === true) {
        count++;
      }
    });
  
    return count;
  }

  const draw = (p5) => {
    
    p5.clear();
    p5.image(img_header,level.x-7, 0)
    countDownTimer.render(p5);
    p5.image(img_tablero,level.x-25, level.y-40)
    p5.noStroke();
    p5.fill("white");
    p5.rect(level.x + 60, 35, 290, 6, 20);
    scores.map((el, i) =>{
      el.render(p5);      
    });
    renderEmptyTiles(p5);
    renderTiles(p5);

    /*p5.image(tuberia_1,level.x-268, p5.height-378)
    p5.image(tuberia_2,level.x+levelwidth+40, 98)
    p5.image(tuberia_3,level.x+levelwidth+26, p5.height-272)*/

    if (!gameover && countDownTimer.isEnded()) {
      gameover = true;
      countDownTimer.pause(); 
      let stars = activeStars(p5);
      fW.setStars(stars);
      setTimeout(() => {
        fW.setVisible();
      },500); 
    }

    let points = checkSolve();
    if (!gameover && points == levels[level.level].points){
      gameover = true;
      countDownTimer.pause(); 
      let stars = activeStars(p5);
      fW.setStars(stars);
      setTimeout(() => {
        fW.setVisible();
      },500); 
    } 
    if (gameover) {
      fW.render(p5);   
    }

    
  };

  const checkSolve = () => {
    let sum = 0;
    for (let i = 0, count = 0; i < level.columns; i++) {
      for (let j = 0; j < level.rows; j++, count++) {
        const tileType = level.tiles[i][j].getTileType();
        level.tiles[i][j].setInPlace(false);
        const targetTile = levels[level.level].end[count];
        if (targetTile !== -1 && tileType >= 1 && tileType < 9) {
          if (
            tileType === targetTile ||
            (tileType === 2 && targetTile === 1) ||
            (tileType === 3 && targetTile === 4) ||
            (tileType === 1 && targetTile === 2) ||
            (tileType === 4 && targetTile === 3)
          ) {
            sum++;
            level.tiles[i][j].setInPlace(true);
          }
        }
      }
    }
    return sum;
  };

  
  const mousePressed = (p5, event) => {
    if (gameover) return;
    if (paused) return;
    pos = {x:p5.mouseX/scale, y:p5.mouseY/scale}

    if (!drag) {
      let mt = getMouseTile(pos);
      if (mt.valid) {
          let swapped = false;
          if (level.selectedtile.selected!=undefined) {
              if (mt.x == level.selectedtile.column && mt.y == level.selectedtile.row) {
                  deselectAll();
                  level.selectedtile.selected = undefined;
                  drag = true;
                  return;
              } else if (canSwap(mt.x, mt.y, level.selectedtile.column, level.selectedtile.row)){
                  mouseSwap(mt.x, mt.y, level.selectedtile.column, level.selectedtile.row);
                  swapped = true;
              }
          }
          
          if (!swapped && level.tiles[mt.x][mt.y].getTileType()>=1 && level.tiles[mt.x][mt.y].getTileType()<9) {
              deselectAll();
              level.selectedtile.column = mt.x;
              level.selectedtile.row = mt.y;
              //level.tiles[mt.x][mt.y].select();
              level.selectedtile.selected = level.tiles[mt.x][mt.y];
          }
      } else {
        deselectAll();
        level.selectedtile.selected = undefined;
      }
      drag = true;
    }
  }

  const mouseReleased = (p5, event) =>{
    drag = false;
  }

  const getMouseTile = (pos) => {
    let tx = Math.floor((pos.x - level.x) / level.tilewidth);
    let ty = Math.floor((pos.y - level.y) / level.tileheight);
    if (tx >= 0 && tx < level.columns && ty >= 0 && ty < level.rows) {
        return {
            valid: true,
            x: tx,
            y: ty
        };
    }else return {
        valid: false,
        x: 0,
        y: 0
    };
  }

  const mouseSwap = (c1, r1, c2, r2) => {
    const fx_1 = new Audio('/assets/fx_move.mp3');
    if (isFxEnabled) {
      fx_1.play();
    }
    currentmove = {targetCol: c1, targetRow: r1, sourceCol: c2, sourceRow: r2};
    deselectAll();
    level.selectedtile.selected = undefined;
    gamestate = gamestates.resolve;
  }

  const canSwap = (x1, y1, x2, y2) => {
    if ((Math.abs(x1 - x2) == 1 && y1 == y2) ||
        (Math.abs(y1 - y2) == 1 && x1 == x2)) {
        if (level.tiles[x1][y1] && level.tiles[x1][y1].getTileType() && level.tiles[x1][y1].getTileType() == -1) return true;
        else return false;
    }
    return false;
  }

  function swiped(event) {
    if (!gameover && drag && level.selectedtile.selected) {
      if (event.direction == 4) {
        //console.log("right");
        if (canSwap(level.selectedtile.column+1, level.selectedtile.row, level.selectedtile.column, level.selectedtile.row)){
          mouseSwap(level.selectedtile.column+1, level.selectedtile.row, level.selectedtile.column, level.selectedtile.row);
        }
      } else if (event.direction == 8) {
        //console.log("up");
        if (canSwap(level.selectedtile.column, level.selectedtile.row-1, level.selectedtile.column, level.selectedtile.row)){
          mouseSwap(level.selectedtile.column, level.selectedtile.row-1, level.selectedtile.column, level.selectedtile.row);
        }
      } else if (event.direction == 16) {
        //console.log("down");
        if (canSwap(level.selectedtile.column, level.selectedtile.row+1, level.selectedtile.column, level.selectedtile.row)){
          mouseSwap(level.selectedtile.column, level.selectedtile.row+1, level.selectedtile.column, level.selectedtile.row);
        }
      } else if (event.direction == 2) {
        //console.log("left");
        if (canSwap(level.selectedtile.column-1, level.selectedtile.row, level.selectedtile.column, level.selectedtile.row)){
          mouseSwap(level.selectedtile.column-1, level.selectedtile.row, level.selectedtile.column, level.selectedtile.row);
        }
      }
    }
  }

  const scaleCanvas = (p5) => {
    scale = (window.innerHeight+15) / p5.height;
    document.getElementById("Game3").style.transform = "translate(-50%, -50%) scale("+scale+")";        
  }

  const windowResized = (p5) => {    
    //scaleCanvas(p5);
  }

  const button1HandleClick = () => {
    document.getElementById("root").style.backgroundImage = "none";
    window.location.href = "/#/menu";    
  }

  const startGame = ()=>{
    paused = false;
    countDownTimer.start();
  }

  const closeHelp = () => {
    document.getElementById("game3Ayuda").style.display = "none";
    startGame();
  }


  return (
    
    <div className='Game3' id="Game3">
        <img id='game3Ayuda' onClick={closeHelp} src="ayudas/game3.png"/>
        <Sketch windowResized={windowResized} mousePressed={mousePressed} mouseReleased={mouseReleased} setup={setup} preload={preload} draw={draw} />
    </div>
    
  )
  
}