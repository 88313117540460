
import Matter from "matter-js";

export default class FloorC {
    constructor(x, y, w, h, _world) {
      const options = {
        isStatic:true,
        label: 'ground',
        restitution: 0.5
      };
      this.x = x;
      this.y = y;
      this.w = w;
      this.h = h;
      this.body = Matter.Bodies.rectangle(x, y, w, h, options);
      Matter.World.add(_world, this.body);      
    }
  
    show(p5) {
        /*const pos = this.body.position;
        const angle = this.body.angle;
        p5.push();
        p5.strokeWeight(1);
        p5.stroke("green");
        p5.rectMode(p5.CENTER);
        p5.noFill();
        p5.rect(pos.x, pos.y, this.w, this.h,20);
        p5.pop();*/
    }
  }