import React, { useEffect, useState, useParams, useCallback, useRef} from 'react';
import _ from 'lodash';
import "./Datos.css";
import anime from 'animejs';

import SettingsService from "../../services/settings";

export default function Menu(props) {  

  const [name, setName] = useState("");
  
  let scale = 1;

  useEffect(() =>{
    document.getElementById("root").style.backgroundImage = "url(/assets/datos/bg.jpg)";
    if (document.getElementById("p5_loading")) document.getElementById("p5_loading").style.display = "none";
    scaleCanvas();
    if (document.getElementById("p5_loading_intro")) {
      document.getElementById("p5_loading_intro").style.display = "block";
      document.getElementById("p5_loading_intro").style.opacity = 1;
      anime({
        targets: document.getElementById("p5_loading_intro"),
        opacity:0,
        duration: 1000,
        delay:1000,
        easing: 'easeInOutQuad',   
        complete: (anim) => {
          document.getElementById("p5_loading_intro").style.display = "none";
        }
      });
    }
  },[])

  useEffect(() => {
    function handleResize() {
      scaleCanvas();
    }

    window.addEventListener('resize', handleResize)
  })

  const button1HandleClick = (event) => {
    event.preventDefault();
    if (name != "") {
      SettingsService.set('name', name);
      window.location.href = "/#/dificultad";
    }        
  }

  const scaleCanvas = () => {
    let gameSize = {w:1920,h:640};
    scale = (window.innerHeight+20) / gameSize.h;
    document.getElementById("defaultCanvas0").style.transform = "translate(-50%, -50%) scale("+scale+")";        
  }

  const changeHandler = (event) => {
    setName(event.target.value)
  }


  return (
    <div className='Datos' id="defaultCanvas0">
      <div className='content'>
        <img className='bg' src="/assets/datos/panel.png"></img>
        <div className='formulario'>
          <input type="text" onChange={changeHandler} value={name} placeholder="Introduce tu nombre"></input>
          <button style={{ backgroundImage: "url(/assets/datos/boton.png)"}} onClick={button1HandleClick}></button>          
        </div>
      </div>        
    </div>
  )
  
}