import React, { useEffect, useState, useParams, useCallback, useRef} from 'react';
import _ from 'lodash';
import "./Menu.css";
import anime from 'animejs';
import ImageMap from "./components/menu/ImageMap";
import Settings from "../blocks/common/Settings";
import VideoWindow, { showVideo } from "../blocks/common/VideoWindow";
import TutorialWindow, { showTutorial } from "../blocks/common/TutorialWindow";
import SettingsService from "../../services/settings";

export default function Menu(props) {  
  
  let gameSize = {w:960,h:640};
  let mapSize = {w:2138,h:1425};
  let scale = 1;
  let mapaImages = {};
  let videoImages = {};
  let posX=0;
  let posY=0;
  let menuStartOffsetX;
  let menuStartOffsetY;
  let mouseDragOffsetX;
  let mouseDragOffsetY;
  let dragging = false;
  let GameMap=gameSize;
  let isMusicEnabled = SettingsService.isMusicEnabled();
  let isFxEnabled = SettingsService.isFxEnabled();
  let difficulty = SettingsService.getDifficulty();
  let name = SettingsService.get('name');
  let map = SettingsService.get('map');
  let trees = SettingsService.get('trees');
  let stars = SettingsService.get('stars');

  useEffect(() => {
    setup();
  },[]);

  const setup = () => {
    scaleCanvas();

    if (!name) {
      window.location.href = "/#/datos";
      return; 
    }
        
    document.getElementById("menu").style.width = (GameMap.w*2) + "px";
    document.getElementById("menu").style.height = (GameMap.h*2) + "px";
        
    posX = map.x || GameMap.w || 0;
    posY = map.y || GameMap.h || 0;

    document.getElementById("root").style.backgroundImage = "none";
    document.getElementById("overlay_left").style.display = "none";
    document.getElementById("overlay_right").style.display = "none";
    
    document.getElementById("Settings").style.bottom = "5vh";
    document.getElementById("Settings").style.left = "10vw";
    document.getElementById("Settings").style.transform = "translate(-50%, -50%) scale("+scale+")";   
    
    document.getElementById("scoreBoxName").style.top = "10vh";
    document.getElementById("scoreBoxName").style.left = "20vw";
    document.getElementById("scoreBoxName").style.transform = "translate(-50%, -50%) scale("+scale+")";   

    document.getElementById("scoreBoxStars").style.top = "10vh";
    document.getElementById("scoreBoxStars").style.left = "65vw";
    document.getElementById("scoreBoxStars").style.transform = "translate(-50%, -50%) scale("+scale+")";   

    document.getElementById("scoreBoxTrees").style.top = "10vh";
    document.getElementById("scoreBoxTrees").style.left = "85vw";
    document.getElementById("scoreBoxTrees").style.transform = "translate(-50%, -50%) scale("+scale+")";   
    
    document.getElementById("text1").innerHTML = name;
    document.getElementById("scoreBoxName").style.backgroundImage = "url(/assets/general/box.png)";
    document.getElementById("text2").innerHTML = trees;
    document.getElementById("scoreBoxStars").style.backgroundImage = "url(/assets/general/box.png)";
    document.getElementById("text3").innerHTML = stars;
    document.getElementById("scoreBoxTrees").style.backgroundImage = "url(/assets/general/box.png)";

    document.getElementById("icon1").style.backgroundImage = "url(/assets/general/icon_1.png)";
    document.getElementById("icon2").style.backgroundImage = "url(/assets/general/icon_2.png)";
    document.getElementById("icon3").style.backgroundImage = "url(/assets/general/icon_3.png)";

    if (document.getElementById("p5_loading")) document.getElementById("p5_loading").style.display = "none";

    if (document.getElementById("p5_loading_intro")) {
      document.getElementById("p5_loading_intro").style.display = "block";
      document.getElementById("p5_loading_intro").style.opacity = 1;
      anime({
        targets: document.getElementById("p5_loading_intro"),
        opacity:0,
        duration: 1000,
        delay:1000,
        easing: 'easeInOutQuad',   
        complete: (anim) => {
          document.getElementById("p5_loading_intro").style.display = "none";
        }
      });
    }

    document.getElementById("menu").style.left = posX + "px";
    document.getElementById("menu").style.top =  posY + "px";

    document.getElementById("menuContainer").addEventListener('mousemove', (event) => {
      mouseMoved({x: event.screenX, y: event.screenY});
    }); 
 
    document.getElementById("menuContainer").addEventListener('mousedown', (event) => {
      mousePressed({x: event.screenX, y: event.screenY});
    });  

    document.getElementById("menuContainer").addEventListener('mouseup', (event) => {
      mouseReleased();
    });
    
    document.getElementById("menuContainer").addEventListener('touchmove', (event) => {
      mouseMoved({x: event.touches[0].screenX, y: event.touches[0].screenY});
    }); 
    
    document.getElementById("menuContainer").addEventListener('touchstart', (event) => {
      mousePressed({x: event.touches[0].screenX, y: event.touches[0].screenY});
    });  

    document.getElementById("menuContainer").addEventListener('touchend', (event) => {
      mouseReleased();
    });
    
    let game1 = SettingsService.get("game1");
    let game2 = SettingsService.get("game2");
    let game3 = SettingsService.get("game3");
    let game4 = SettingsService.get("game4");

    if (game1.status==1 && !SettingsService.get("video0Shown")){
      launchVideo(0);
    }

    if (game1.level>=1 && game2.level>=1 && game3.level>=1 && game4.level>=1 && !SettingsService.get("video5Shown")){
      launchVideo(5);
    }

  };

  const scaleCanvas = () => {
    const canvasRatio = gameSize.w / gameSize.h;
    const windowRatio = window.innerWidth / window.innerHeight;
    if (windowRatio > canvasRatio) {  
      scale = window.innerHeight / gameSize.h
    } else {
      scale = window.innerWidth / gameSize.w;
    }
    
    document.getElementById("menu").style.transform = "translate(-50%, -50%) scale("+scale+")";       
  }

  const mousePressed = (coords) => {        
    dragging = true;      
    menuStartOffsetX = document.getElementById("menu").style.left ? parseFloat(document.getElementById("menu").style.left) : 0;
    menuStartOffsetY = document.getElementById("menu").style.top ? parseFloat(document.getElementById("menu").style.top) : 0;
    mouseDragOffsetX = coords.x;
    mouseDragOffsetY = coords.y;
  }

  const mouseReleased = () => {
    dragging = false;
  }

  const mouseMoved = (coords) => {
    if (dragging) { 
      posX = (menuStartOffsetX + coords.x - mouseDragOffsetX);
      posY = (menuStartOffsetY + coords.y - mouseDragOffsetY);

      const halfWidth = document.getElementById("menu").offsetWidth * scale / 2;
      const halfHeight = document.getElementById("menu").offsetHeight * scale / 2;
      const containerWidth = document.getElementById("menuContainer").offsetWidth;
      const containerHeight = document.getElementById("menuContainer").offsetHeight;

      if (posX > halfWidth+200) { posX = halfWidth+200; }
      if (posY > halfHeight+200) { posY = halfHeight+200; }
      if (posX < containerWidth - halfWidth - 300) { posX = containerWidth - halfWidth - 300; }
      if (posY < containerHeight - halfHeight - 200) { posY = containerHeight - halfHeight - 200; }

      document.getElementById("menu").style.left = posX + "px";
      document.getElementById("menu").style.top =  posY + "px";
    }
  }

  const mouseClicked = (p5, event) => { 
    //GameMap.checkAction(p5, p5.mouseX/scale, p5.mouseY/scale, posX, posY);
  }

  const musicEnabledChanged = () => {
    isMusicEnabled = SettingsService.isMusicEnabled();

    let music = document.getElementById("music");
    if (music) {
      isMusicEnabled ? music.play() : music.pause();
    }
  }

  const fxEnabledChanged = () => {
    isFxEnabled = SettingsService.isFxEnabled();
  }

  const difficultyChanged = () => {
    difficulty = SettingsService.getDifficulty();
  }

  const launchVideo = videoId => {
    scaleCanvas();
    showVideo(videoId, scale);
    SettingsService.set(`video${videoId}Shown`, true);
  }

  const launchTutorial = tutorialId => {
    showTutorial(tutorialId, scale);
  }

  const setPosition = () =>{
    let map = SettingsService.get('map');
    map.x = posX;
    map.y = posY;
    SettingsService.set('map', map);
  }

  return (
    <div id="menuContainer">
      <Settings musicEnabledChanged={musicEnabledChanged} fxEnabledChanged={fxEnabledChanged} difficultyChanged={difficultyChanged} />          
      <VideoWindow />
      <TutorialWindow />
      <div id="scoreBoxName" className="scoreBox"><span className="icono" id="icon1"></span><span className="texto" id="text1"></span></div>
      <div id="scoreBoxStars" className="scoreBox"><span className="icono" id="icon2"></span><span className="texto" id="text2"></span></div>
      <div id="scoreBoxTrees" className="scoreBox"><span className="icono" id="icon3"></span><span className="texto" id="text3"></span></div>
      <div className='Menu' id="menu">
        <ImageMap launchTutorial={launchTutorial} scale={scale} launchVideo={launchVideo} setPosition={setPosition} />
      </div>
      {/*<div className='Menu' id="menu">
          <Sketch id="menuCanvas" mouseClicked={mouseClicked} setup={setup} preload={preload} draw={draw} />
    </div>*/}
    </div>
  )
  
}