import React, {useState} from 'react';
import _ from 'lodash';

import "./Settings.css";

import SettingsService from "../../../services/settings"

export default function Settings(props) {
    
    const [visible, setVisible] = useState(false);
    const [music, setMusic] = useState(SettingsService.isMusicEnabled());
    const [fx, setFx] = useState(SettingsService.isFxEnabled());
    const [difficulty, setDifficulty] = useState(SettingsService.getDifficulty());

    const triggerCallback = (callback, data) => {
        if (props[callback]) {
            props[callback](data);
        }
    };

    const toggleMusic = (e) => {
        e.stopPropagation();
        SettingsService.toggleMusic();
        const isMusicEnabled = SettingsService.isMusicEnabled();
        setMusic(isMusicEnabled);
        triggerCallback('musicEnabledChanged', isMusicEnabled);
    }
    
    const toggleFx = (e) => {
        e.stopPropagation();
        SettingsService.toggleFx();
        const isFxEnabled = SettingsService.isFxEnabled();
        setFx(isFxEnabled);
        triggerCallback('fxEnabledChanged', isFxEnabled);
    }


    const toggleDifficulty = (e) => {
        e.stopPropagation();
        let difficulty = SettingsService.getDifficulty();
        console.log('pre', difficulty);
        difficulty = (difficulty < 2) ? ++difficulty : 0;
        console.log('pos', difficulty);
        SettingsService.changeDifficulty(difficulty);
        setDifficulty(difficulty);
        triggerCallback('difficultyChanged', SettingsService.getDifficulty());
    } 

    return (
        <div id="Settings">
            <button id="settingsButton" onClick={(e) => { setVisible(!visible)}} style={{backgroundImage:"url(assets/general/btn_menu.png)"}}></button>
            { visible &&
                <div id="settingsOptions">
                    <div className="option" onClick={toggleMusic}>
                        Música: <span>{ music ? 'on' : 'off' }</span>
                    </div>
                    <div className="option" onClick={toggleFx}>
                        Efectos: <span>{ fx ? 'on' : 'off' }</span>
                    </div>
                    <div className="option" onClick={toggleDifficulty}>
                        Dificultad: <span>{ difficulty == 0 ? 'fácil' : difficulty == 1 ? 'media' : 'difícil' }</span>
                    </div>
                </div>
            }
        </div>
    );
    
}