import Matter from "matter-js";

export default class SlingShot {
    constructor(x, y, body, r, _world) {
      const options = {
        pointA: {
          x: x,
          y: y
        },
        bodyB: body,
        r:r,
        stiffness: 0.05,
        length: 30
      };
      this.sling = Matter.Constraint.create(options);
      Matter.World.add(_world, this.sling);
    }
  
    fly() {
      this.sling.bodyB = null;
    }
  
    show(p5) {
      if (this.sling.bodyB) {
        p5.stroke("#2b160a");
        p5.strokeWeight(4);
        const posA = this.sling.pointA;
        const posB = this.sling.bodyB.position;
        p5.line(posA.x+25, posA.y-3, posB.x, posB.y);
        p5.line(posA.x, posA.y+3, posB.x, posB.y);          
      }
    }
  
    attach(body) {
      this.sling.bodyB = body;
    }
  }