import anime from 'animejs';

import SettingsService from "../../../../services/settings";

export default class Bubble {
    constructor(id, initialscore, image, staron) {
        this.id = id;
        this.image = image;
        this.staron = staron;
        this.x = 0;
        this.y = 45;
        this.w = 50;
        this.h = 50;
        this.scale = 1;
        this.score = initialscore;
        this.anim = false;
        this.isfinished = false;
        this.isFxEnabled = SettingsService.isFxEnabled();
    }

    setX(newx){
        this.x = newx;
    }

    setY(newy){
        this.y = newy;
    }

    getX(){
        return this.x;
    }

    getY(){
        return this.y;
    }

    jiggle(){
        if (this.anim) return;
        this.anim = true;
        anime({
            targets: this,
            scale: 2,
            duration: 300,
            easing: 'easeInOutQuad',
            direction: 'alternate',
            complete: (anim) => {
                if (anim.completed) this.anim = false;
            }   
        });
    }
    
    setImage(newimage){
        this.image = newimage;
    }

    playSound(dif){
        for (let k=0; k<dif; k++){
            const fx_1 = new Audio('/assets/fx_1.mp3');
            if (this.isFxEnabled) {
                setTimeout(()=>fx_1.play(), (Math.random()*200)*k);
            }
        }
    }

    playStarSound(){
        const fx_1 = new Audio('/assets/fx_star.mp3');
        if (this.isFxEnabled) {
            fx_1.play();
        }
    }

    setScore(newScore) {
        const dif = this.score - newScore;
        if (newScore<0) this.score = 0;
        else this.score = newScore;
        if (dif>0) { 
            this.playSound(dif);
            this.jiggle();
        }

        if (this.score===0 && !this.isfinished){
            this.isfinished = true;
            this.playStarSound();
        }
    }

    getScore() {
        return this.score;
    }

    getFinished() {
        return this.isfinished;
    }

    displayText(p5){
        p5.fill("white"); //Color para el texto
        p5.noStroke();
        p5.textFont('FinkHeavy');
        p5.textSize(35);  
        p5.text(this.score, this.x + this.w + 20, this.y+10, 150);
    }


    displayImage(p5){
        p5.push();
        p5.translate(this.x+(this.w/2), this.y+(this.h/2));
        p5.scale(this.scale,this.scale);
        if (this.score!==0)
            p5.image(this.image, -(this.w/2), -(this.h/2), this.w, this.h);              
        else p5.image(this.staron, -(this.w/2), -(this.h/2), this.w, this.h);
        p5.pop();
    }
  
    render(p5) {        
        this.displayImage(p5);                
        this.displayText(p5);        
    }
  }